@import '../constants.scss';

.pst1 {
  width: 918px;
  position: relative;
  font-family: 'Arial';
  color: #000;
  padding: indent(6) 61px indent(6) 61px;

  &__main {
    display: flex;
  }

  .main-left {
    width: 429px;
    margin-right: 56px;

    &__spacing {
      margin-left: 92px;
    }

    &__payments {
      width: 100%;
    }

    &__deduction {
      margin-top: 28px;
      width: 100%;
    }
  }

  .main-left-top {
    &__codes {
      display: inline-block;
      padding: 2px 3px 2px 1px;
      background: rgba(196, 196, 196, 0.3);
    }

    &__company-about {
      margin-top: 17px;
      margin-left: 2px;
    }

    &__status {
      margin-top: indent(3);
      font-size: 10px;
      line-height: 12px;
      margin-bottom: 62px;

      th, td {
        padding-bottom: 1px;
      }

      th {
        width: 174px;
        padding-bottom: 2px;
      }
    }
  }

  .codes {
    &__head {
      text-align: center;

      th {
        font-weight: normal;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 12px;
        padding-right: indent(1);
        padding-top: 3px;

        &:last-child {
          padding-right: 0;
        }
      }
    }

    &__body {
      text-align: center;

      td {
        padding-right: indent(1);
        font-size: 10px;
        line-height: 12px;
      }
    }
  }

  .payments {
    &__head {
      th {
        &:first-child {
          width: 92px;
          font-size: 14px;
        }
      }
    }

    &__body {
      td {
        &:nth-child(3) {
          padding-right: 7px;
        }
        &:nth-child(4n) {
          padding-right: 4px;
        }
      }
    }
  }

  .payments-thead {
    &__item {
      font-size: 11px;
      line-height: 16px;
      font-weight: 700;
      padding-bottom: 4px;
      min-width: 70px;
      border-bottom: 1px solid #000;
      text-align: right;
      padding-right: 8px;

      &--empty {
        width: 41px;
      }

      &--salary {
        min-width: 140px;
      }
    }
  }

  .payments-tbody {
    &__item {
      padding-top: 4px;
      font-size: 13px;
      height: 16px;
      text-align: right;

      &--accent {
        text-align: right;
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        padding-top: 4px;
        padding-bottom: 5px;
        background-color: #E6E6E6;
        border-top:1px solid #000;
        border-bottom:1px solid #000;
      }
    }
  }

  .deduction {

    &__head {
      th {
        font-size: 14px;
        line-height: 16px;

        &:first-child {
          width: 97px;
        }

        &:nth-child(2) {
          width: 137px;
          padding-left: 3px;
        }
        &:nth-child(3) {
          width: 82px;
        }
      }
    }

    &__body {
      tr{
        &:nth-last-child(5) {

          td {
            &:nth-child(1), &:nth-child(2), &:nth-child(3) {
              // border-bottom: 1px solid #000;
              padding-bottom: 4px;
            }
          }
        }

        &:nth-last-child(4) {

          td {
            padding-top: 6px;
            padding-bottom: 4px;
          }
        }
      }

      td {
        font-size: 13px;
        line-height: 16px;

        &:nth-child(2) {
          padding-left: 3px;
        }

        &:nth-child(3) {
          padding-right: 4px;
        }
      }
    }
  }

  .deduction-thead {
    &__item {
      padding-bottom: 6px;
      border-bottom: 1px solid #000;

      &--empty {
        width: 41px;
      }
    }
  }

  .deduction-tbody {
    &__item {
      padding-top: 4px;
      padding-bottom: 5px;

      &--secondary {
        padding-top: 6px;
        padding-bottom: 4px;
      }

      &--active {
        padding-top: 5px;
        padding-bottom: 5px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        background-color: #E6E6E6;
        font-weight: 700;
      }
      &--other {
        font-weight: 700;
        text-decoration: underline;
      }
    }
    &__custom {
      margin-top: indent(2);
    }
  }

  .main-right {
    &__head {
      display: flex;
    }

    &__logo {
      margin-top: 3px;
      margin-left: 9px;

      img {
        width: 85px;
        height: 85px;
        object-fit: contain;
        object-position: top center;
      }
    }

    &__title {
      font-size: 24px;
      line-height: 28px;
      font-weight: 700;
      margin-bottom: 14px;

      &--smaller {
        font-size: 22px;
      }
    }

    &__payment-period {
      font-size: 12px;
      line-height: 12px;
      width: 100%;

      th,td {
        padding-bottom: 7px;
      }

      th {
        width: 137px;
        font-weight: 400;
      }
    }

    &__employee {
      margin-top: 22px;
      margin-bottom: 33px;
    }

    &__other-info {
      tr {
        vertical-align: bottom;
      }

      th {

        &:first-child {
          width: 153px;
          padding-left: 2px;
          padding-right: 25px;
          font-size: fs(s)
        }

        &:last-child {
          padding-right: 6px;
        }
      }

      td {
        font-size: 13px;
        line-height: 16px;
        padding-top: 6px;
        padding-bottom: 6px;

        &:nth-child(3n) {
          padding-right: 6px;
          text-align: right;
        }
      }
    }
  }

  .other-info {
    &__head-item {
      font-size: 11px;
      line-height: 16px;
      font-weight: 700;
      min-width: 79px;
      border-bottom: 1px solid #000;
      padding-bottom: 4px;
    }
  }


  &__footer {
    position: relative;
    margin-top: 273px;
  }

  .footer {
    display: flex;
  }

  .footer-left {
    width: 94px;

    &__logo {
      img {
        width: 85px;
        height: 85px;
        object-fit: contain;
        object-position: top center;
      }
    }
  }

  .footer-right {
    &__top {
      display: flex;
    }

    &__middle {
      margin-top: 18px;
    }

    &__bottom {
      margin-top: 53px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .footer-right-top {
    &__left {
      width: 390px;
    }

    &__about-company {
      margin-top: 2px;
      margin-left: 6px;
      font-weight: 400;
    }

    &__right {
      width: 311px;
    }
  }

  .footer-right-middle {
    &__order-payment {
      width: 100%;

      th {
        padding-bottom: 6px;
        border-bottom:2px solid #000;
        font-size: fs(s);
        line-height: 18px;
        text-transform: uppercase;

        &:first-child {
          text-transform: none;
          width: 93px;
          padding-left: 6px;
        }
      }

      td {
        font-size: fs(s);
        text-transform: uppercase;
        padding-top: 2px;
        padding-bottom: 1px;
        border-bottom:2px solid #000;

        &:first-child {
          text-transform: none;
          width: 93px;
          padding-left: 6px;
          border-bottom: none;
        }

        &:nth-child(2) {
          width: 486px;
          border-right:2px solid #000;
          background-color: #E6E6E6;
        }

      }
    }
    &__account-payment {
      width: 100%;
      margin-top: indent(4);

      th {
        padding-bottom: 4px;
        border-bottom:2px solid #000;
        font-size: fs(s);
        line-height: 18px;

        &:first-child {
          text-transform: none;
          width: 42%;
          padding-left: 6px;
        }
        &:nth-child(2) {
          width: 25%;
        }
      }

      td {
        font-size: fs(xs);
        text-transform: uppercase;
        padding-top: 2px;
        padding-bottom: 1px;

        &:first-child {
          font-weight: bold;
          text-transform: uppercase;
          width: 93px;
          padding-left: 6px;
          border-bottom: none;
        }
      }
    }
  }

  .footer-right-bottom {
    &__non-negotiable {
      margin-right: 91px;
    }
  }

  &__watermark {

    .watermark-check {
      width: 559px;
      margin-bottom: 53px;
      bottom: 0;
    }

    .watermark-preview {
      width: 504px;
      top: 0;
      margin-top: 16px;
    }
  }
}

