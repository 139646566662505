@import '../constants.scss';

.pst2 {
	width: 918px;
	min-height: 900px;
	padding: indent(6) 62px indent(6) 62px;
	position: relative;
	font-family: 'Arial';
	color: #000000;

	&__top {
		display: flex;
		justify-content: space-between;
	}

	.top {
		&__base-metrics {
			margin-top: 3px;
			margin-left: 4px;
		}

		&__advice {
			width: 100%;
			max-width: 209px;
		}
	}

	.advice {
		&__row {
			display: flex;
			justify-content: space-between;
			font-size: 14px;
			line-height: 18px;
		}
	}

	&__middle {
		margin-top: 77px;
	}

	.middle {
		&__payment-table {
			width: 100%;

			th {
				border-bottom: 1px solid #000;
				padding-bottom: 7px;
			}

			td {
				padding-top: 3px;
			}

			th,
			td {
				font-size: 14px;
				line-height: 16px;

				&:first-child {
					padding-left: 4px;
				}
			}
		}

		&__negotiable-wrapper {
			display: flex;
			justify-content: flex-end;
			margin-top: 55px;
		}
	}

	&__bottom {
		margin-top: 61px;
		margin-left: -1px;
		margin-right: -1px;
	}

	.bottom {
		&__employee {
			width: 100%;
			font-size: 10px;
			line-height: 11px;
			margin-bottom: 10px;
		}

		&__notes {
			width: 100%;
			font-size: 10px;
			line-height: 11px;
			margin-bottom: 10px;
		}

		&__payment {
			width: 100%;
			font-size: 10px;
			line-height: 11px;
			margin-bottom: 7px;
		}

		&__tables-wrapper {
			display: flex;
			justify-content: space-between;
		}
	}

	.employee {
		&__head {
			background-color: #e6e6e6;
			vertical-align: baseline;
			border: 1px solid #000;

			th {
				font-weight: 400;
				padding: 4px 2px 3px 4px;
				width: 133px;
				border-right: 1px solid #000;

				&:nth-last-child(3),
				&:nth-last-child(2) {
					width: 88px;
				}

				&:nth-last-child(1) {
					width: 87px;
				}
			}
		}

		&__body {
			border: 1px solid #000;

			td {
				padding: 5px 2px 5px 4px;
				border: 1px solid #000;
			}
		}
	}

	.notes {
		&__head {
			background-color: #e6e6e6;
			vertical-align: baseline;
			border: 1px solid #000;

			th {
				font-weight: 400;
				padding: 4px 2px 3px 4px;
			}
		}

		.notes__body {
			border: 1px solid #000;

			td {
				padding: 2px 4px 3px 4px;
			}

			p {
				&:not(:last-child) {
					margin-bottom: 2px;
				}
			}
		}
	}

	.payment {
		&__head {
			background-color: #e6e6e6;
			vertical-align: baseline;
			border: 1px solid #000;

			th {
				font-weight: 400;
				padding: 3px 4px 3px 4px;
				text-align: right;
				border-right: 1px solid #000;
				width: 133px;
			}
		}

		&__body {
			td {
				text-align: right;
				padding: 3px 4px 4px 4px;
				border: 1px solid #000;
			}
		}
	}

	.tables-wrapper {
		&__left {
			width: 493px;
		}

		&__right {
			width: 294px;
		}
	}

	.income-table {
		font-size: 10px;
		line-height: 11px;

		&__thead {
			th {
				font-weight: normal;
			}
		}

		&__tbody {
			vertical-align: baseline;
			border-left: 1px solid #000;
			border-right: 1px solid #000;

			td {
				padding: 1px 2px 4px 4px;
			}

			tr {
				&:last-child {
					height: 89px;
				}
			}
		}

		&__tfoot {
			border: 1px solid #000;

			td {
				padding: 4px 2px 4px 4px;
			}
		}
	}

	.table-thead {
		&__th {
			font-weight: 400;
			padding: 3px 4px 3px 4px;
			text-align: center;
			background-color: #e6e6e6;
			vertical-align: baseline;
			border: 1px solid #000;

			&--secondary {
				padding: 4px 2px 4px 4px;
				border-bottom: 1px solid #000;

				&:first-child {
					border-left: 1px solid #000;
				}

				&:last-child {
					border-right: 1px solid #000;
				}
			}

			&--left-first {
				min-width: 59px;
			}

			&--right-first {
				min-width: 187px;
			}
		}
	}

	&__watermark {
		.watermark-preview {
			width: 504px;
			margin-top: 178px;
		}

		.watermark-check {
			width: 559px;
			top: 0;
			margin-top: 59px;
		}
	}
}
