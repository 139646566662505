@import 'src/styles/constants';

.header {
	padding-top: indent(1);
	padding-bottom: indent(1);
	background-color: color(white);
	position: sticky;
	top: 0;
	z-index: 100;

	@include md-up {
		padding-top: 20px;
		padding-bottom: 20px;
		background-color: rgba(color(white), 0.9);
		backdrop-filter: saturate(180%) blur(20px);
	}
}

.dots-btn {
	border: none;
	background: transparent;
}

.header-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

