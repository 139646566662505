@import 'src/styles/constants';

.nav-bottom {
	margin-top: 42px;

	@include md-up {
		margin-top: 0;
	}
}

.menu-bottom {
	@include reset-list();
	display: flex;

	@include xl-up {
		justify-content: flex-end;
	}

	&__item {
		font-size: fs(s);
		min-width: 50%;

		@include md-up {
			min-width: auto;

			&:not(:last-child) {
				margin-right: 35px;
			}
		}

		@include lg-up {
			font-size: fs(m);

			&:not(:last-child) {
				margin-right: 55px;
			}
		}

		@include xl-up {
			&:not(:last-child) {
				margin-right: indent(6);
			}
		}

		&:last-child {
			padding-left: indent(2);

			@include md-up {
				padding-left: 0;
			}
		}
	}

	&__link {
		color: color(white);

		&:hover, &--active {
			color: color(orange500);
		}
	}
}
