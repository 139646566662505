@use 'sass:math';

@import '../constants.scss';

$container-full-width: 918;
$container-inner-width: 918 - 122;
$font-size-adjust: 1.125;

@function strip-units($number) {
	@return math.div($number, ($number * 0 + 1));
}

@function cqw-size($size, $container-size: $container-inner-width) {
	@return (math.div(strip-units($size), strip-units($container-size)) * 100) + unquote('cqw');
}

.pst2--new {
	width: 100%;
	min-height: initial;
	padding: cqw-size(62px, $container-full-width) cqw-size(48px, $container-full-width);
	position: relative;
	font-family: 'Arial';
	color: #000000;

	&__top {
		display: flex;
		justify-content: space-between;
	}

	.top {
		&__base-metrics {
			margin-top: cqw-size(3px);
			margin-left: cqw-size(4px);
		}

		&__advice {
			width: 100%;
			max-width: cqw-size(209px);
		}
	}

	.advice {
		&__row {
			display: flex;
			justify-content: space-between;
			font-size: cqw-size(14px);
			line-height: cqw-size(18px);
		}
	}

	&__middle {
		margin-top: cqw-size(77px);
	}

	.middle {
		&__payment-table {
			width: 100%;

			th {
				border-bottom: 1px solid #000;
				padding-bottom: cqw-size(7px);
			}

			td {
				padding-top: cqw-size(3px);
			}

			th,
			td {
				font-size: cqw-size(14px);
				line-height: cqw-size(16px);

				&:first-child {
					padding-left: cqw-size(4px);
				}
			}
		}

		&__negotiable-wrapper {
			display: flex;
			justify-content: flex-end;
			margin-top: cqw-size(55px);
		}
	}

	&__bottom {
		margin-top: cqw-size(61px);
		margin-left: cqw-size(1px);
		margin-right: cqw-size(1px);
	}

	.bottom {
		&__employee {
			width: 100%;
			font-size: cqw-size(10px);
			line-height: cqw-size(11px);
			margin-bottom: cqw-size(10px);
		}

		&__notes {
			width: 100%;
			font-size: cqw-size(10px);
			line-height: cqw-size(11px);
			margin-bottom: cqw-size(10px);
		}

		&__payment {
			width: 100%;
			font-size: cqw-size(10px);
			line-height: cqw-size(11px);
			margin-bottom: cqw-size(7px);
		}

		&__tables-wrapper {
			display: flex;
			justify-content: space-between;
		}
	}

	.employee {
		&__head {
			background-color: #e6e6e6;
			vertical-align: baseline;
			border: 1px solid #000;

			th {
				font-weight: 400;
				padding: cqw-size(4px) cqw-size(2px) cqw-size(3px) cqw-size(4px);
				width: cqw-size(133px);
				border-right: 1px solid #000;

				&:nth-last-child(3),
				&:nth-last-child(2) {
					width: cqw-size(88px);
				}

				&:nth-last-child(1) {
					width: cqw-size(87px);
				}
			}
		}

		&__body {
			border: 1px solid #000;

			td {
				padding: 5px 2px 5px cqw-size(4px);
				border: 1px solid #000;
			}
		}
	}

	.notes {
		&__head {
			background-color: #e6e6e6;
			vertical-align: baseline;
			border: 1px solid #000;

			th {
				font-weight: 400;
				padding: 4px 2px 3px cqw-size(4px);
			}
		}

		.notes__body {
			border: 1px solid #000;

			td {
				padding: 2px 4px 3px cqw-size(4px);
			}

			p {
				&:not(:last-child) {
					margin-bottom: cqw-size(2px);
				}
			}
		}
	}

	.payment {
		&__head {
			background-color: #e6e6e6;
			vertical-align: baseline;
			border: 1px solid #000;

			th {
				font-weight: 400;
				padding: 3px 4px 3px cqw-size(4px);
				text-align: right;
				border-right: 1px solid #000;
				width: cqw-size(133px);
			}
		}

		&__body {
			td {
				text-align: right;
				padding: 3px 4px 4px cqw-size(4px);
				border: 1px solid #000;
			}
		}
	}

	.tables-wrapper {
		&__left {
			width: cqw-size(493px);
		}

		&__right {
			width: cqw-size(294px);
		}
	}

	.income-table {
		font-size: cqw-size(10px);
		line-height: cqw-size(11px);

		&__thead {
			th {
				font-weight: normal;
			}
		}

		&__tbody {
			vertical-align: baseline;
			border-left: 1px solid #000;
			border-right: 1px solid #000;

			td {
				padding: 1px 2px 4px cqw-size(4px);
			}

			tr {
				&:last-child {
					height: cqw-size(89px);
				}
			}
		}

		&__tfoot {
			border: 1px solid #000;

			td {
				padding: 4px 2px 4px cqw-size(4px);
			}
		}
	}

	.table-thead {
		&__th {
			font-weight: 400;
			padding: 3px 4px 3px cqw-size(4px);
			text-align: center;
			background-color: #e6e6e6;
			vertical-align: baseline;
			border: 1px solid #000;

			&--secondary {
				padding: 4px 2px 4px cqw-size(4px);
				border-bottom: 1px solid #000;

				&:first-child {
					border-left: 1px solid #000;
				}

				&:last-child {
					border-right: 1px solid #000;
				}
			}

			&--left-first {
				min-width: cqw-size(59px);
			}

			&--right-first {
				min-width: cqw-size(187px);
			}
		}
	}

	.watermark-wrapper {
		.watermark-check {
			width: cqw-size(559px);
			margin-bottom: cqw-size(53px);
			bottom: 0;
		}

		.watermark-preview {
			width: cqw-size(504px);
			top: 0;
			margin-top: cqw-size(16px);
		}
	}

	.paystub-base-metrics__item {
		font-style: italic;
		font-size: cqw-size(14px);
		line-height: cqw-size(12px);
		text-transform: uppercase;

		&:not(:last-child) {
			margin-bottom: cqw-size(6px);
		}
	}

	.pst-non-negotiable {
		text-transform: uppercase;
		font-weight: 700;
		font-size: cqw-size(24px);
		line-height: cqw-size(28px);
	}
}
