@import '../constants.scss';

.invoice {
	color: #000;
	
	.watermark-wrapper {
		position: relative;
		z-index: 2;
	}
	thead {
		display: table-header-group;
		vertical-align: middle;
		border-color: inherit;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	.width20 {
		width: 20% !important;
	}

	.width80 {
		width: 80% !important;
	}

	hr:not(.invoice-spacing) {
		margin-top: 0;
		margin-bottom: 10px;
	}

	&__inner {
		font-size: 12px;
		line-height: normal;
		margin: 0 auto;
		max-width: 760px;
		border: 1px solid #ddd;
		margin-bottom: 30px;
		position: relative;
		width: 100%;
		&:last-child {
			margin-bottom: 0;
		}

		&:last-child:not(:first-child) {
			margin-top: 30px;
		}
	}

	&__title-text {
		margin-bottom: 20px;
	}

	.invoice-preview {
		padding: 15px;
	}

	&__row {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-left: -15px;
		margin-right: -15px;
	}
	&__col {
		padding-left: 15px;
		padding-right: 15px;
		width: 50%;
	}
	&__fields {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-left: -3px;
		margin-right: -3px;
	}

	&__field {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-items: center;
		align-items: center;
		margin-bottom: 6px;
		padding-left: 3px;
		padding-right: 3px;
		position: relative;
		width: 100%;
	}

	&__title {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
	}

	&__title-inner h2 {
		font-size: 22px;
		font-weight: 700;
	}

	&__logo-inner {
		height: 105px;
		width: 177px;
		margin-bottom: 20px;
		position: relative;
	}

	&__logo-img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center;
	}
}

.invoice-detail-header label,
.invoice-detail-terms label {
	margin-bottom: 0;
	line-height: 1;
}

.invoice-list {
	&__table {
		width: 100%;
	}
	table thead tr {
		border-top: 1px solid;
		border-bottom: 1px solid;
		font-weight: 700;
	}

	table thead th {
		vertical-align: bottom;
	}

	table th {
		padding: 7px 5px;
		text-align: right;
	}

	table th.invoice-table__title--description {
		background-color: #dedede;
	}

	table td.invoice-items__cell--summary,
	table th.invoice-table__title--description {
		text-align: left;
		width: 100%;
	}

	table th.invoice-detail-tax {
		text-align: center;
	}
	table td,
	table th {
		padding: 3px;
		vertical-align: top;
	}
	table td {
		padding-top: 8px;
		padding-bottom: 8px;
	}
}

.invoice-table__body {
	tr {
		border-bottom: 1px dashed;
	}

	tr:last-child {
		border: none;
	}

	tr:not(.with-tax) td {
		padding-bottom: 0;
	}
}

.invoice-items__cell {
	&--amount,
	&--quantity &--rate {
		min-width: 120px;
		text-align: right;
	}
}

.invoice-totals__row {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.invoice-totals__label {
	margin-left: 0;
	text-align: right;
}

.invoice-totals__value {
	min-width: 125px;
	padding: 0 3px;
	text-align: right;
}

.invoice-spacing {
	margin-top: 27px;
	margin-bottom: 31px;
}

.invoice-list {
	padding-top: 15px;
	table thead th {
		background: #dedede;
		padding: 6px;
	}
	table td,
	table th {
		padding-left: 6px;
		padding-right: 6px;
	}
	table td:first-child,
	table th:first-child {
		padding-left: 15px;
	}
	table td:last-child,
	table th:last-child {
		padding-right: 15px;
	}
}

.invoice-items__cell--quantity,
.invoice-items__cell--rate {
	min-width: auto;
}

.invoice-totals__row ~ .invoice-totals__row {
	padding-top: 5px;
}

.invoice-totals__row strong {
	font-size: 15px;
}

.invoice-totals .invoice-totals__value {
	padding-right: 12px;
}

.invoice-detail-notes {
	min-height: 100px;
	padding-top: 10px;
}

@media screen and (max-width: 767px) {
	.invoice {
		margin-left: -15px;
		margin-right: -15px;
		padding-left: 15px;
		overflow-x: auto;
		overflow-y: hidden;
	}
	.invoice > div {
		display: inline-block;
		margin-right: 15px;
		width: 760px;
	}
}
