@use 'sass:math';

@import '../constants.scss';

$container-full-width: 918;
$container-inner-width: 918 - 122;
$font-size-adjust: 1.125;

@function strip-units($number) {
	@return math.div($number, ($number * 0 + 1));
}

@function cqw-size($size, $container-size: $container-inner-width) {
	@return (math.div(strip-units($size), strip-units($container-size)) * 100) + unquote('cqw');
}

.pst3--new {
	width: 100%;
	position: relative;
	font-family: 'Arial';
	color: #000;
	padding: cqw-size(48px, $container-full-width) cqw-size(61px, $container-full-width);

	&__top {
		display: flex;
		justify-content: space-between;
		margin-bottom: cqw-size(31px);
	}

	.top {
		&__company-about {
			font-weight: 400;
			margin-top: cqw-size(1px);
			margin-left: cqw-size(12px);

			.paystub-base-metrics__item {
				font-size: cqw-size(18px);
				line-height: cqw-size(21px);
				margin-bottom: 0;
			}
		}

		&__title {
			text-transform: uppercase;
			font-size: cqw-size(24px);
			line-height: cqw-size(28px);
			font-weight: 700;
			margin-right: cqw-size(14px);
			margin-top: cqw-size(2px);
		}
	}

	.bottom {
		&__base-table {
			width: 100%;
			border: 1px solid #000;
		}
	}

	.base-table {
		&__thead {
			border: 1px solid #000;
			background-color: #3c3c3c;
		}

		&__th {
			padding-top: cqw-size(7px);
			padding-bottom: cqw-size(7px);
			text-align: center;
			font-size: cqw-size(10px);
			line-height: cqw-size(11px);
			font-weight: 900;
			text-transform: uppercase;
			color: #fff;
			border-right: 1px solid #000;

			&:first-child {
				width: cqw-size(287px);
			}

			&:nth-child(2) {
				width: cqw-size(150px);
			}

			&:nth-child(3) {
				width: cqw-size(208px);
			}
		}

		&__tbody {
			font-size: cqw-size(14px);
			line-height: cqw-size(18px);
		}

		&__td {
			text-align: center;
			padding: cqw-size(7px) cqw-size(12px);
			border: 1px solid #000;

			&--wide-left,
			&--wide-right {
				padding: 0;
				vertical-align: baseline;
				background: #dedede;
			}

			&--wide-left {
				border-right: 1px solid #000;
				border-bottom: 1px solid #000;
			}

			&--wide-right {
				border-bottom: 1px solid #000;
			}
		}
	}

	.base-table-metrics {
		font-weight: 400;
		text-align: left;

		.paystub-base-metrics__item {
			font-size: cqw-size(14px);
			line-height: cqw-size(15px);
			margin-bottom: 0;
		}
	}

	.payments {
		width: 100%;

		&__head {
			background: #fff;
		}

		&__body {
			height: 100%;
			vertical-align: baseline;
		}

		&__head,
		&__body {
			text-align: center;
		}

		&__th,
		&__td {
			font-size: cqw-size(11px);
			line-height: cqw-size(11px);

			&:first-child {
				text-align: left;
				padding-left: cqw-size(12px);
				width: cqw-size(175px);
			}

			&:nth-child(2) {
				padding-right: 0;
			}

			&:nth-child(3) {
				text-align: left;
			}

			&:last-child {
				text-align: right;
				padding-right: cqw-size(12px);
			}
		}

		&__th {
			padding-top: cqw-size(6px);
			padding-bottom: cqw-size(6px);
			text-transform: uppercase;
			font-weight: 700;
			border-bottom: 1px solid #000;
			padding-right: cqw-size(7px);
		}

		&__td {
			padding-top: cqw-size(10px);
			padding-bottom: cqw-size(10px);

			&:first-child {
				font-size: cqw-size(14px);
				line-height: cqw-size(16px);
				font-weight: 700;
				text-transform: uppercase;
			}

			&:nth-child(3) {
				padding-left: cqw-size(10px);
			}
		}

		&__tr {
			&:last-child {
				height: cqw-size(67px);
			}
		}
	}

	.deduction {
		width: 100%;

		&__head {
			background: #fff;
		}

		&__body {
			height: 100%;
			vertical-align: baseline;
		}

		&__th {
			padding-top: cqw-size(6px);
			padding-bottom: cqw-size(6px);
			text-transform: uppercase;
			font-weight: 700;
			border-bottom: 1px solid #000;

			&:first-child {
				width: cqw-size(184px);
			}

			&:last-child {
				padding-right: cqw-size(12px);
			}
		}

		&__th,
		&__td {
			font-size: cqw-size(11px);
			line-height: cqw-size(11px);

			&:not(:first-child) {
				text-align: right;
			}

			&:first-child {
				padding-left: cqw-size(12px);
			}
		}

		&__td {
			padding-bottom: cqw-size(10px);

			&:last-child {
				padding-right: cqw-size(7px);
			}

			&--wide {
				font-size: cqw-size(14px);
				line-height: cqw-size(18px);
				text-transform: uppercase;
				font-weight: 700;
				padding-left: cqw-size(12px);
				padding-top: cqw-size(10px);
				padding-bottom: cqw-size(12px);
			}
		}

		&__tr {
			&:last-child {
				height: cqw-size(67px);
			}
		}
	}

	.base-tfoot {
		width: 100%;
		font-size: cqw-size(11px);
		line-height: cqw-size(11px);

		&__cell {
			display: inline-block;
			width: 33.333333%;
			text-align: center;
		}
	}

	.tfoot-cell {
		&__title {
			text-transform: uppercase;
			padding-top: cqw-size(5px);
			padding-bottom: cqw-size(5px);
		}

		&__value {
			display: block;
			padding-top: cqw-size(4px);
			padding-bottom: cqw-size(4px);
		}
	}

	.tfoot-td-left {
		.tfoot-cell {
			&:first-child {
				text-indent: cqw-size(-7px);
			}

			&:nth-child(2) {
				text-indent: cqw-size(-37px);
			}

			&:nth-child(3) {
				text-indent: cqw-size(-67px);
			}
		}
	}

	.tfoot-td-right {
		.tfoot-cell {
			&:first-child {
				text-indent: cqw-size(-71px);
			}

			&:nth-child(2) {
				text-indent: cqw-size(-43px);
			}

			&:nth-child(3) {
				text-indent: cqw-size(-17px);
			}
		}
	}

	.watermark-wrapper {
		.watermark-preview {
			width: cqw-size(243px);
			margin-top: cqw-size(27px);
			top: 0;
		}
	}

	.paystub-base-metrics__item {
		font-size: cqw-size(14px);
		line-height: cqw-size(15px);
	}
}
