@import '../constants.scss';

.pst5 {
  width: 918px;
  position: relative;
  font-family: 'Arial';
  color: #000;
  padding: 67px 61px indent(6) 61px;

  &__watermark {

    .watermark-preview {
      width: 514px;
      top: -16px;
      margin-left: 6px;
    }
  }

  .top {
    margin-bottom: 20px;

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 18px;
      padding-right: 18px;
      margin-bottom: 17px;
    }

    &__company-about {
      font-weight: 400;
    }

    &__date {
      text-align: right;
      font-size: 13px;
      line-height: 11px;
    }

    &__body {
      display: flex;
      justify-content: space-between;
      background-color: #F1F1F1;
      padding: 15px 18px 16px 18px;

    }

    &__pay-text {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .payment {
    text-align: right;

    &__value {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 9px;
    }

    &__notice {
      font-size: 11px;
      line-height: 11px;
    }
  }

  .middle {
    margin-bottom: 17px;

    &__top {
      display: flex;
      padding-left: 18px;
      margin-bottom: 57px;
    }

    &__title {
      font-size: 14px;
      line-height: 18px;
      margin-right: 34px;
    }

    &__employee {
      margin-top: 2px;
      font-weight: 400;
    }

    &__bottom {
      padding-left: 18px;
      padding-right: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__earning-title {
      margin-top: 2px;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      text-align: right;
    }
  }

  .middle-company {
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;

    .paystub-base-metrics__item {

      &:not(:last-child) {
        margin-bottom: 3px;
      }
    }
  }

  .table-wrapper {
    padding-left: 18px;
    padding-right: 18px;
  }

  .employee {
    width: 100%;

    &__tbody {
      vertical-align:baseline;
    }

    &__th, &__td {
      font-size: 11px;
      line-height: 11px;
      padding-bottom: 10px;

      &:first-child {
        padding-left: 11px;
      }

      &:not(:first-child) {
        text-align: center;
      }
    }

    &__th {
      padding-top: 10px;
      background-color: #DEDEDE;

      &:first-child {
        width: 267px;
      }
    }

    &__td {
      padding-top: 9px;
    }
  }

  .employee-metrics {
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;

    .paystub-base-metrics__item {
      &:not(:last-child) {
        margin-bottom: 3px;
      }
    }
  }

  .earnings-deduction-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .earnings {
    width: 435px;
    margin-top: 13px;
    align-self: flex-start;

    &__tbody {
      vertical-align: baseline;
        &::before {
          content: '';
          display: block;
          height: 18px;
        }
    }

    &__th, &__td {
      font-size: 11px;
      line-height: 11px;
      padding-right: 10px;

      &:first-child {
        padding-left: 11px;
      }

      &:not(:first-child) {
        text-align: right;
      }
    }

    &__th {
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #DEDEDE;

      &:first-child {
        width: 158px;
      }

      &:nth-child(2) {
        width: 56px;
      }

      &:nth-child(3) {
        width: 51px;
      }

      &:nth-child(4) {
        width: 81px;
      }
    }

    &__td {
      padding-bottom: 4px;

      &:nth-child(3) {
        text-align: right;
        text-indent: 2px;
      }
    }
  }

  .deductions {
    width: 325px;
    margin-top: 13px;
    align-self: flex-start;

    &__tbody {
      vertical-align: baseline;
        &::before {
          content: '';
          display: block;
          height: 18px;
        }
    }

    &__th, &__td {
      font-size: 11px;
      line-height: 11px;
      padding-right: 12px;

      &:first-child {
        padding-left: 15px;
      }

      &:not(:first-child) {
        text-align: right;
      }
    }

    &__th {
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #DEDEDE;

      &:first-child {
        width: 113px;
      }

      &:nth-child(2) {
        width: 80px;
      }

      &:nth-child(3) {
        width: 80px;
      }
    }

    &__td {
      padding-bottom: 7px;
    }
  }

  .earnings-total {
    width: 439px;
    margin-top: 28px;
    align-self: flex-start;


    &__th, &__td {
      font-size: 11px;
      line-height: 11px;
      font-weight: 700;
    }

    &__th {
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #DEDEDE;
      margin-bottom: 18px;
      padding-left: 11px;

      &:first-child {
        width: 160px;
      }
    }

    &__td {
      text-align: right;
      padding-right: 12px;
      background-color: #F1F1F1;

      &--left {
        width: 188px;
      }
    }
  }

  .deductions-total {
    width: 321px;
    margin-top: 28px;
    align-self: flex-start;

    &__th, &__td {
      font-size: 11px;
      line-height: 11px;
      font-weight: 700;
    }

    &__th {
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #DEDEDE;
      margin-bottom: 18px;
      padding-left: 11px;

      &:first-child {
        width: 134px;
      }
    }

    &__td {
      text-align: center;
      background-color: #F1F1F1;
      width: 92px;
    }
  }

  .payment-total-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
  }

  .payment-total {
    &__th, &__td {
      font-size: 11px;
      line-height: 11px;
      font-weight: 700;
      width: 114px;
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 4px solid color(white);
    }

    &__th {
      background-color: #DEDEDE;
    }

    &__td {
      background-color: #F1F1F1;
    }
  }

  &--green {
    .top__body,
    .earnings-total__td,
    .deductions-total__td,
    .payment-total__td  {
        background-color: #70dd7f;
      }
    
    
    .employee__th,
    .earnings__th,
    .deductions__th,
    .earnings-total__th,
    .deductions-total__th,
    .payment-total__th {
      background-color: #38B449;
      color: #fff
    }
  }

  &--blue {
    .top__body,
    .earnings-total__td,
    .deductions-total__td,
    .payment-total__td  {
        background-color: #90aeff;
      }
    
    
    .employee__th,
    .earnings__th,
    .deductions__th,
    .earnings-total__th,
    .deductions-total__th,
    .payment-total__th {
      background-color: #4673EC;
      color: #fff
    }
  }

}
