@import '../constants.scss';

.invoice2 {
	--gray: #333;
	--gray-light: #f5f5f5;
	--blue: #5072e4;
	--bd-radius: 8px;
	--blue-light: #96adf9;
	--green: #5fb157;
	--green-light: #8cda88;

	--text-description: #777777;

	--theme-color: var(--gray);
	--theme-color-light: var(--gray-light);

	&.green {
		--theme-color: var(--green);
		--theme-color-light: var(--green-light);
		--text-description: color(black);
	}
	&.blue {
		--theme-color: var(--blue);
		--theme-color-light: var(--blue-light);
		--text-description: color(black);
	}

	color: #000;
	overflow: hidden;

	&__header {
		background: var(--theme-color);
		border-radius: var(--bd-radius);
		display: flex;
		align-items: center;
		color: color(white);
		justify-content: space-between;
		flex-direction: row;
		padding: 26px 30px 25px;
		margin-bottom: 43px;
	}

	.header {
		text-align: center;

		&__logo-block {
			height: 105px;
			width: 180px;
			border-radius: var(--bd-radius);
			overflow: hidden;
		}

		&__logo-placeholder {
			color: color(black);
			background: #ffff;
			text-transform: uppercase;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 22px;
			font-weight: 700;
			width: 100%;
			height: 100%;
		}

		&__title {
			text-align: center;
			max-width: 40%;
			word-break: break-word;

			.title {
				padding-bottom: 13px;
				font-size: 28px;
				text-transform: none;
			}

			.number {
				font-size: 22px;
			}
		}

		.details {
			font-size: 14px;
			border-top: 1px solid #777777;
			border-bottom: 1px solid #777777;
			border-radius: var(--bd-radius);
			padding: 23px 15px 22px;

			&__item {
				text-align: left;
			}

			&__name {
				width: 51px;
				display: inline-block;
			}
		}
	}

	&__info {
		margin-bottom: 50px;
	}

	.info {
		&__title {
			text-transform: none;
			margin-bottom: 7px;
		}

		&__row {
			display: flex;
			justify-content: space-between;
		}

		&__col {
			width: 381px;
		}

		&__separator {
			background-color: #e4e4e4;
			width: 1px;
			min-height: 100%;
			margin: 26.5px 60px;
		}

		&__field {
			color: #777;
			font-size: 14px;

			a {
				color: #777;
			}
		}

		.field {
			&__header {
				font-size: 22px;
				color: color(black);
				text-transform: none;
				padding-bottom: 9px;
			}
		}
	}

	&__table {
		padding: 10px 20px 18px;
		border-radius: var(--bd-radius);
		border-bottom: 1px solid #eaeaea;
		background: var(--theme-color-light);
		margin-bottom: 43px;
	}

	.table {
		&__cell {
			width: 141px;
			padding: 6px 20px 10px;
		}

		&__cell--first {
			padding-left: 0;
			width: 380px;
		}

		&__cell--td {
			padding-left: 20px;

			&:first-child {
				padding: 14px 10px 7px 0;
			}
		}

		&__title {
			font-size: 16px;
			font-weight: normal;
			padding-bottom: 4px;
		}

		&__description {
			color: var(--text-description);
			display: block;
			line-height: 19px;
			font-size: 14px;
		}
	}

	.total {
		margin-bottom: 47px;
		padding-right: 105px;
		font-size: 16px;

		&__item {
			display: flex;
			justify-content: flex-end;
			width: 100%;
		}
		&__name {
			margin-right: 30px;
		}
		&__value {
			min-width: 74px;
		}
	}

	.grand-total {
		margin-bottom: 43px;
		border-radius: var(--bd-radius);
		background: var(--theme-color);
		display: flex;
		justify-content: flex-end;
		color: white;
		padding: 4px 79px 6px 0;
		gap: 20px;
		font-size: 22px;
		letter-spacing: 0.32px;

		&__value {
			font-weight: 700;
		}
	}

	.notes {
		color: #777;
		font-size: 14px;
		letter-spacing: 0.14px;
		line-height: 19px;
	}
}
