@import 'styles/constants';

.wrapper {
	--border-width: 1px;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	color: #fff;
	text-align: center;
	text-transform: uppercase;
	font-size: fs(tab);
	font-weight: 700;

	padding: calc(indent(2) - var(--border-width)) calc(indent(2.5) - var(--border-width));

	background: var(--blue700);
	border: var(--border-width) solid var(--blue700);
	border-radius: 10px;
	position: relative;
	outline: none;
	transition: all 0.2s ease;
	cursor: pointer;

	&:focus,
	&:hover {
		text-decoration: none;
		color: color(orange500);
	}

	&:active {
		background: color(blue800);
		border-color: color(blue800);
		color: color(orange500);
	}

	&[disabled] {
		background: rgba(var(--blue700-rgb), 0.5);
		border-color: transparent;
		box-shadow: none;
		color: color(white);
		cursor: default;
	}

	&.isLoading {
		text-align: center;

		.content,
		.preContent {
			opacity: 0;
			visibility: hidden;
		}
	}
}

.spinner {
	position: absolute;
	align-items: center;
	display: flex;
}

.content {
	@include text-one-row;
	max-width: 100%;
}

.preContent {
	margin-right: indent(1);
	display: inline-flex;
}

// Variants:

.secondary {
	border-color: color(blue400);
	background: color(white);
	color: color(blue400);

	&:focus,
	&:hover {
		border-color: color(orange500);
	}

	&:active {
		background: color(white);
		border-color: color(orange500);
		color: color(orange500);
	}

	&[disabled] {
		background: color(white);
		color: color(blue400);
		border-color: color(blue400);
		opacity: 0.5;
		cursor: default;
	}
}

.text,
.text-simple {
	border: none;
	background: none;
	color: color(blue400);
	line-height: 14px;
	padding: 0;
	font-size: fs(xxs);
	transition: none;

	.preContent {
		margin-right: indent(0.5);
		fill: color(blue400);
	}

	&:hover,
	&:focus {
		color: color(orange500);
		.preContent {
			fill: color(orange500);
		}
	}

	&:active {
		background: none;
		color: color(blue800);
		.preContent {
			fill: color(blue800);
		}
	}

	&[disabled] {
		background: none;
		color: rgba(color(blue700), 0.5);
		.preContent {
			fill: rgba(color(blue700), 0.5);
		}
	}
}
.brainableStatic {
	position: relative;
	text-transform: none;
	min-width: 200px;
	z-index: 1;
	border-radius: 50px;
	font-weight: 500;
	background: color(blue400);
	font-family: inherit;
	text-align: center;
	border: 2px solid color(blue400);
	line-height: 1.9;
	font-size: 16px;
	&:hover {
		border-color: color(blue800);
		color: color(white);
	}
	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		pointer-events: none;
		border-radius: inherit;
		animation: pulse 2.5s cubic-bezier(0.66, 0, 0, 1) 0s infinite normal none running;
	}
}

.brainableStatic {
	font-weight: 600;
	font-size: 18px;
	padding: 20px 35px;
	&:before {
		display: none;
	}
	@media (min-width: 768px) {
		font-size: 23px;
	}
}
// Variants END

// SIZES

.big {
	width: 176px;
}

.small {
	min-width: 116px;
	font-size: fs(xxs);
	padding: 10px;
	line-height: 1;
	border-radius: 5px;
}

// SIZES END
