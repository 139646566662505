@import '../constants.scss';

.pst3 {
  width: 918px;
  position: relative;
  font-family: 'Arial';
  color: #000;
  padding: indent(6) 61px;

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 31px;
  }

  .top {
    &__company-about {
      font-weight: 400;
      margin-top: 1px;
      margin-left: 12px;

      .paystub-base-metrics__item {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 0;
      }
    }

    &__title {
      text-transform: uppercase;
      font-size: 24px;
      line-height: 28px;
      font-weight: 700;
      margin-right: 14px;
      margin-top: 2px;
    }
  }

  .bottom {
    &__base-table {
      width: 100%;
      border: 1px solid #000;
    }
  }

  .base-table {
    &__thead {
      border: 1px solid #000;
      background-color: #3C3C3C;
    }

    &__th {
      padding-top: 7px;
      padding-bottom: 7px;
      text-align: center;
      font-size: 10px;
      line-height: 11px;
      font-weight: 900;
      text-transform: uppercase;
      color: #fff;
      border-right: 1px solid #000;

      &:first-child {
        width: 287px;
      }

      &:nth-child(2) {
        width: 150px;
      }

      &:nth-child(3) {
        width: 208px;
      }
    }

    &__tbody {
      font-size: 14px;
      line-height: 18px;
    }

    &__td {
      text-align: center;
      padding: 7px 12px;
      border: 1px solid #000;

      &--wide-left, &--wide-right {
        padding: 0;
        vertical-align: baseline;
        background: #DEDEDE;
      }

      &--wide-left {
        border-right: 1px solid #000;
        border-bottom: 1px solid #000;
      }

      &--wide-right {
        border-bottom: 1px solid #000;
      }
    }
  }

  .base-table-metrics {
    font-weight: 400;
    text-align: left;

    .paystub-base-metrics__item {
      font-size: 14px;
      line-height: 15px;
      margin-bottom: 0;
    }
  }

  .payments {
    width: 100%;

    &__head {
      background: #fff;
    }

    &__body {
      height: 100%;
      vertical-align: baseline;
    }

    &__head, &__body {
      text-align: center;
    }

    &__th, &__td {
      font-size: 11px;
      line-height: 11px;

      &:first-child {
        text-align: left;
        padding-left: 12px;
        width: 175px;
      }

      &:nth-child(2) {
        padding-right: 0;
      }

      &:nth-child(3) {
        text-align: left;
      }

      &:last-child {
        text-align: right;
        padding-right: 12px;
      }
    }

    &__th {
      padding-top: 6px;
      padding-bottom: 6px;
      text-transform: uppercase;
      font-weight: 700;
      border-bottom: 1px solid #000;
      padding-right: 7px;
    }

    &__td {
      padding-top: 10px;
      padding-bottom: 10px;

      &:first-child {
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        text-transform: uppercase;
      }

      &:nth-child(3) {
        padding-left: 10px;
      }
    }

    &__tr {
      &:last-child {
        height: 67px;
      }
    }
  }

  .deduction {
    width: 100%;

    &__head {
      background: #fff;
    }

    &__body {
      height: 100%;
      vertical-align: baseline;
    }

    &__th {
      padding-top: 6px;
      padding-bottom: 6px;
      text-transform: uppercase;
      font-weight: 700;
      border-bottom: 1px solid #000;

      &:first-child {
        width: 184px;
      }

      &:last-child {
        padding-right: 12px;
      }
    }

    &__th, &__td {
      font-size: 11px;
      line-height: 11px;

      &:not(:first-child) {
        text-align: right;
      }

      &:first-child {
        padding-left: 12px;
      }
    }

    &__td {
      padding-bottom: 10px;

      &:last-child {
        padding-right: 7px;
      }

      &--wide {
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        font-weight: 700;
        padding-left: 12px;
        padding-top: 10px;
        padding-bottom: 12px;
      }
    }

    &__tr {
      &:last-child {
        height: 67px;
      }
    }
  }

  .base-tfoot {
    width: 100%;
    font-size: 11px;
    line-height: 11px;


    &__cell {
      display: inline-block;
      width: 33.333333%;
      text-align: center;
    }
  }

  .tfoot-cell {
    &__title {
      text-transform: uppercase;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    &__value {
      display: block;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  .tfoot-td-left {
    .tfoot-cell {
      &:first-child {
        text-indent: -7px;
      }

      &:nth-child(2) {
        text-indent: -37px;
      }

      &:nth-child(3) {
        text-indent: -67px;
      }
    }
  }

  .tfoot-td-right {
    .tfoot-cell {
      &:first-child {
        text-indent: -71px;
      }

      &:nth-child(2) {
        text-indent: -43px;
      }

      &:nth-child(3) {
        text-indent: -17px;
      }
    }
  }

  &__watermark {

    .watermark-preview {
      width: 243px;
      margin-top: 27px;
      top: 0;
    }
  }
}
