.wrapper {
	border-radius: 50%;
	border: 3px solid white;
	background-color: rgb(21, 111, 247);
	display: inline-block;
	width: 60px;
	height: 60px;
	padding: 8px;
	text-align: center;
	margin-bottom: 86px;
  transition: all 0.3s ease-in-out;
  &:hover{
    opacity: 0.7;
  }
}
