// common template components styles
.doc-preview-frame {
  box-shadow: 0px 8px 32px rgba(0, 17, 106, 0.08);
  border-radius: 10px;
  position: relative;
}

.pst-preview-mode {
  user-select: none;
}

.watermark-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  user-select: none;
}

.watermark-check, .watermark-preview {
  z-index: 10;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.paystub-base-metrics {
  font-weight: 700;
}

.paystub-base-metrics__item {
  font-style: italic;
  font-size: 14px;
  line-height: 12px;
  text-transform: uppercase;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
}

.pst-non-negotiable {
  text-transform: uppercase;
  font-weight: 700;
  font-size: fs(2);
  line-height: 28px;
}

.advice-info {
  width: 100%;
  font-size: fs(s);

  th, td {
    padding-bottom: 3px;
  }

  td {
    text-align: right;
  }
}

.form1099table-cell {
  font-size: 12px;
  line-height: 14px;
  display: flex;
  margin-bottom: 6px;

  &__marker {
    margin-right: 8px;
  }
}
.table-point {
  width: 100%;
  display: flex;

  &__marker {
    font-weight: 700;
    font-size: 10px;
    line-height: 11px;
    display: inline-block;
    margin-right: 7px;
    flex: 0 0 auto;
  }

  &__content {
    width: 100%;
  }

  &__item {

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    &--center {
      text-align: center;
      margin-left: -10px
    }
  }
}
