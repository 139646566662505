@use 'sass:math';

@import '../constants.scss';

$container-full-width: 918;
$container-inner-width: 918 - 122;
$font-size-adjust: 1.125;

@function strip-units($number) {
	@return math.div($number, ($number * 0 + 1));
}

@function cqw-size($size, $container-size: $container-inner-width) {
	@return (math.div(strip-units($size), strip-units($container-size)) * 100) + unquote('cqw');
}

.pst4--new {
	width: 100%;
	position: relative;
	font-family: 'Arial';
	color: #000;
	padding: cqw-size(48px, $container-full-width) cqw-size(61px, $container-full-width);

	&__base-table {
		width: 100%;
		border: 1px solid #dedede;
	}

	.base-table {
		&__caption {
			padding: 0;
			display: flex;
			justify-content: flex-end;
		}
	}

	.caption {
		text-transform: uppercase;
		font-weight: 700;
		font-size: cqw-size(24px);
		line-height: cqw-size(28px);

		&__title {
			position: relative;
			background: linear-gradient(0deg, #3c3c3c 0%, rgba(60, 60, 60, 0) 164.15%);
			color: #fff;
			padding: cqw-size(12px) cqw-size(11px) cqw-size(11px) cqw-size(76px);

			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: cqw-size(51px) 0 0 cqw-size(53px);
				border-color: transparent transparent transparent #ffffff;
			}
		}
	}

	.company {
		width: 100%;

		&__th,
		&__td {
			font-size: cqw-size(14px);
			line-height: cqw-size(18px);
			padding-top: cqw-size(4px);
			padding-bottom: cqw-size(4px);
			padding-left: cqw-size(11px);
			text-transform: uppercase;
		}

		&__th {
			font-weight: 700;
			background-color: #dedede;

			&:first-child {
				width: cqw-size(262px);
			}
		}

		&__td {
			&:first-child {
				border-right: 1px solid #dedede;
			}
		}
	}

	.employee {
		width: 100%;

		&__th,
		&__td {
			font-size: cqw-size(14px);
			line-height: cqw-size(18px);
			padding-top: cqw-size(4px);
			padding-bottom: cqw-size(4px);
			padding-left: cqw-size(11px);
			text-transform: uppercase;
			&-transform {
				text-transform: none;
			}
		}

		&__th {
			font-weight: 700;
			background-color: #dedede;

			&:first-child {
				width: cqw-size(442px);
			}

			&:nth-child(2) {
				width: cqw-size(176px);
			}

			&-small {
				&:first-child {
					width: cqw-size(320px);
				}
			}
		}

		&__td {
			&:not(:first-child) {
				text-align: center;
			}

			&:not(:last-child) {
				border-right: 1px solid #dedede;
			}
		}
	}

	.tables-wrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.payments {
		width: 100%;

		&__th,
		&__td {
			font-size: cqw-size(14px);
			line-height: cqw-size(18px);
			padding-top: cqw-size(4px);
			padding-bottom: cqw-size(4px);
			&-transform {
				text-transform: none;
			}
		}

		&__th {
			font-weight: 700;
			background-color: #dedede;
			text-transform: uppercase;
			text-align: center;

			&:first-child {
				width: cqw-size(184px);
			}

			&:nth-child(2) {
				width: cqw-size(200px);
			}

			&:nth-child(3) {
				width: cqw-size(117px);
			}
			&:nth-child(4) {
				width: cqw-size(118px);
			}
		}

		&__td {
			text-align: center;
			&:first-child {
				padding-left: cqw-size(11px);
			}
			&:not(:last-child) {
				border-right: 1px solid #dedede;
			}
		}
	}

	.earnings {
		width: 50%;
		border-right: 1px solid #dedede;

		&__tbody {
			vertical-align: baseline;
		}

		&__th,
		&__td {
			padding-left: cqw-size(11px);
			padding-right: cqw-size(11px);

			&:not(:first-child) {
				text-align: right;
			}
		}

		&__th {
			font-size: cqw-size(14px);
			line-height: cqw-size(18px);
			padding-top: cqw-size(4px);
			padding-bottom: cqw-size(4px);
			text-transform: uppercase;
			font-weight: 700;
			background-color: #dedede;

			&:first-child {
				width: cqw-size(117px);
			}
		}

		&__td {
			font-size: cqw-size(13px);
			line-height: cqw-size(18px);
			padding-top: cqw-size(4px);
			padding-bottom: cqw-size(4px);

			&:first-child {
				text-transform: uppercase;
			}
		}
	}

	.deduction {
		width: 50%;

		&__tbody {
			vertical-align: baseline;
		}

		&__th,
		&__td {
			padding-left: cqw-size(11px);
			padding-right: cqw-size(11px);

			&:not(:first-child) {
				text-align: right;
			}
		}

		&__th {
			font-size: cqw-size(14px);
			line-height: cqw-size(18px);
			padding-top: cqw-size(4px);
			padding-bottom: cqw-size(4px);
			text-transform: uppercase;
			font-weight: 700;
			background-color: #dedede;

			&:first-child {
				width: cqw-size(153px);
			}
		}

		&__td {
			font-size: cqw-size(13px);
			line-height: cqw-size(18px);
			padding-top: cqw-size(4px);
			padding-bottom: cqw-size(10px);

			&--last {
				padding-bottom: cqw-size(4px);
			}

			&:first-child {
				text-transform: uppercase;
				padding-right: 0;
			}
		}
	}

	.total {
		width: 100%;

		&__th {
			&:first-child {
				width: cqw-size(128px);
			}

			&:nth-child(2) {
				width: cqw-size(138px);
			}

			&:nth-child(3) {
				width: cqw-size(128px);
			}

			&:nth-child(4) {
				width: cqw-size(131px);
			}

			&:nth-child(5) {
				width: cqw-size(132px);
			}
		}

		&__th,
		&__td {
			font-size: cqw-size(14px);
			line-height: cqw-size(18px);
			padding-top: cqw-size(4px);
			padding-bottom: cqw-size(4px);
			text-transform: uppercase;
			text-align: center;
		}

		&__th {
			font-weight: 700;
			background-color: #dedede;
		}

		&__td {
			&:not(:last-child) {
				border-right: 1px solid #dedede;
			}
		}
	}

	.watermark-wrapper {
		.watermark-preview {
			width: cqw-size(286px);
			margin-top: cqw-size(43px);
			margin-left: cqw-size(-9px);
			top: 0;
		}
	}
}

.pst4--new.pst4--green {
	.caption {
		&__title {
			background: linear-gradient(0deg, #38b449 0%, rgba(56, 180, 73, 40%) 164.15%);
		}
	}

	.pst4 {
		&__base-table {
			border: 1px solid #70dd7f;
		}
	}

	.company__th,
	.employee__th,
	.payments__th,
	.earnings__th,
	.deduction__th,
	.total__th {
		background-color: #70dd7f;
		color: #fff;
	}
}

.pst4--new.pst4--blue {
	.caption {
		&__title {
			background: linear-gradient(0deg, #4673ec 0%, rgba(70, 115, 236, 40%) 164.15%);
		}
	}

	.pst4 {
		&__base-table {
			border: 1px solid #97b2f9;
		}
	}

	.company__th,
	.employee__th,
	.payments__th,
	.earnings__th,
	.deduction__th,
	.total__th {
		background-color: #90aeff;
		color: #fff;
	}
}
