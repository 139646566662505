@import 'src/styles/constants';

.container {
	pointer-events: none;
}

.bar {
	position: fixed;
	top: 0;
	left: 0;
  z-index: 10000;
	width: 100vw;
	background: $gradient-header;
	height: 5px;
}
