@import 'styles/constants';

.error-content {

  &__title {
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    color: color(blue100);
    text-transform: uppercase;
    margin-bottom: indent(4);

    @include md-up {
      font-size: 100px;
      line-height: 120px;
    }
  }

  &__subtitle {
    margin-bottom: indent(2);

    @include md-up {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: indent(3);
    }
  }

  &__description {
    max-width: 187px;
    font-size: fs(m);
    color: color(blue800);
    margin-bottom: indent(3);

    @include md-up {
      min-width: 279px;
      margin-bottom: indent(5);
    }
  }
}