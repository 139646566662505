@import '../constants.scss';
// global styles for all invoice templates

.invoice-template {
	width: 100%;
	min-width: 880px;
	min-height: 1119px;
	height: 100%;
	padding: 50px;

	box-shadow: 0px 8px 32px rgba(0, 17, 106, 0.08);

	@include xl-up {
		max-width: 982px;
	}

	&__container {
		background: #fff;
		width: 100%;
	}

	&__logo-img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}

	.watermark-img {
		width: 100%;
	}

	.watermark-preview {
		width: 70%;
	}
}
