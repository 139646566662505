@use 'sass:math';

@import '../constants.scss';

$container-full-width: 918;
$container-inner-width: 918 - 122;
$font-size-adjust: 1.125;

@function strip-units($number) {
	@return math.div($number, ($number * 0 + 1));
}

@function cqw-size($size, $container-size: $container-inner-width) {
	@return (math.div(strip-units($size), strip-units($container-size)) * 100) + unquote('cqw');
}

.pst5--new {
	width: 100%;
	position: relative;
	font-family: 'Arial';
	color: #000;
	padding: cqw-size(67px, $container-full-width) cqw-size(61px, $container-full-width)
		cqw-size(48px, $container-full-width) cqw-size(61px, $container-full-width);

	.watermark-wrapper {
		.watermark-preview {
			width: cqw-size(514px);
			top: cqw-size(-16px);
			margin-left: cqw-size(6px);
		}
	}

	.top {
		margin-bottom: cqw-size(20px);

		&__head {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-left: cqw-size(18px);
			padding-right: cqw-size(18px);
			margin-bottom: cqw-size(17px);
		}

		&__company-about {
			font-weight: 400;
		}

		&__date {
			text-align: right;
			font-size: cqw-size(13px);
			line-height: cqw-size(11px);
		}

		&__body {
			display: flex;
			justify-content: space-between;
			background-color: #f1f1f1;
			padding: cqw-size(15px) cqw-size(18px) cqw-size(16px) cqw-size(18px);
		}

		&__pay-text {
			font-size: cqw-size(14px);
			line-height: cqw-size(18px);
		}
	}

	.payment {
		text-align: right;

		&__value {
			font-size: cqw-size(14px);
			line-height: cqw-size(18px);
			margin-bottom: cqw-size(9px);
		}

		&__notice {
			font-size: cqw-size(11px);
			line-height: cqw-size(11px);
		}
	}

	.middle {
		margin-bottom: cqw-size(17px);

		&__top {
			display: flex;
			padding-left: cqw-size(18px);
			margin-bottom: cqw-size(57px);
		}

		&__title {
			font-size: cqw-size(14px);
			line-height: cqw-size(18px);
			margin-right: cqw-size(34px);
		}

		&__employee {
			margin-top: cqw-size(2px);
			font-weight: 400;
		}

		&__bottom {
			padding-left: cqw-size(18px);
			padding-right: cqw-size(18px);
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&__earning-title {
			margin-top: cqw-size(2px);
			font-weight: 700;
			font-size: cqw-size(14px);
			line-height: cqw-size(18px);
			text-align: right;
		}
	}

	.middle-company {
		font-weight: 400;
		font-size: cqw-size(13px);
		line-height: cqw-size(12px);

		.paystub-base-metrics__item {
			&:not(:last-child) {
				margin-bottom: cqw-size(3px);
			}
		}
	}

	.table-wrapper {
		padding-left: cqw-size(18px);
		padding-right: cqw-size(18px);
	}

	.employee {
		width: 100%;

		&__tbody {
			vertical-align: baseline;
		}

		&__th,
		&__td {
			font-size: cqw-size(11px);
			line-height: cqw-size(11px);
			padding-bottom: cqw-size(10px);

			&:first-child {
				padding-left: cqw-size(11px);
			}

			&:not(:first-child) {
				text-align: center;
			}
		}

		&__th {
			padding-top: cqw-size(10px);
			background-color: #dedede;

			&:first-child {
				width: cqw-size(267px);
			}
		}

		&__td {
			padding-top: cqw-size(9px);
		}
	}

	.employee-metrics {
		font-weight: 400;
		font-size: cqw-size(13px);
		line-height: cqw-size(12px);

		.paystub-base-metrics__item {
			&:not(:last-child) {
				margin-bottom: cqw-size(3px);
			}
		}
	}

	.earnings-deduction-wrapper {
		display: flex;
		justify-content: space-between;
	}

	.earnings {
		width: cqw-size(435px);
		margin-top: cqw-size(13px);
		align-self: flex-start;

		&__tbody {
			vertical-align: baseline;
			&::before {
				content: '';
				display: block;
				height: cqw-size(18px);
			}
		}

		&__th,
		&__td {
			font-size: cqw-size(11px);
			line-height: cqw-size(11px);
			padding-right: cqw-size(10px);

			&:first-child {
				padding-left: cqw-size(11px);
			}

			&:not(:first-child) {
				text-align: right;
			}
		}

		&__th {
			padding-top: cqw-size(10px);
			padding-bottom: cqw-size(10px);
			background-color: #dedede;

			&:first-child {
				width: cqw-size(158px);
			}

			&:nth-child(2) {
				width: cqw-size(56px);
			}

			&:nth-child(3) {
				width: cqw-size(51px);
			}

			&:nth-child(4) {
				width: cqw-size(81px);
			}
		}

		&__td {
			padding-bottom: cqw-size(4px);

			&:nth-child(3) {
				text-align: right;
				text-indent: cqw-size(2px);
			}
		}
	}

	.deductions {
		width: cqw-size(325px);
		margin-top: cqw-size(13px);
		align-self: flex-start;

		&__tbody {
			vertical-align: baseline;
			&::before {
				content: '';
				display: block;
				height: cqw-size(18px);
			}
		}

		&__th,
		&__td {
			font-size: cqw-size(11px);
			line-height: cqw-size(11px);
			padding-right: cqw-size(12px);

			&:first-child {
				padding-left: cqw-size(15px);
			}

			&:not(:first-child) {
				text-align: right;
			}
		}

		&__th {
			padding-top: cqw-size(10px);
			padding-bottom: cqw-size(10px);
			background-color: #dedede;

			&:first-child {
				width: cqw-size(113px);
			}

			&:nth-child(2) {
				width: cqw-size(80px);
			}

			&:nth-child(3) {
				width: cqw-size(80px);
			}
		}

		&__td {
			padding-bottom: cqw-size(7px);
		}
	}

	.earnings-total {
		width: cqw-size(439px);
		margin-top: cqw-size(28px);
		align-self: flex-start;

		&__th,
		&__td {
			font-size: cqw-size(11px);
			line-height: cqw-size(11px);
			font-weight: 700;
		}

		&__th {
			padding-top: cqw-size(10px);
			padding-bottom: cqw-size(10px);
			background-color: #dedede;
			margin-bottom: cqw-size(18px);
			padding-left: cqw-size(11px);

			&:first-child {
				width: cqw-size(160px);
			}
		}

		&__td {
			text-align: right;
			padding-right: cqw-size(12px);
			background-color: #f1f1f1;

			&--left {
				width: cqw-size(188px);
			}
		}
	}

	.deductions-total {
		width: cqw-size(321px);
		margin-top: cqw-size(28px);
		align-self: flex-start;

		&__th,
		&__td {
			font-size: cqw-size(11px);
			line-height: cqw-size(11px);
			font-weight: 700;
		}

		&__th {
			padding-top: cqw-size(10px);
			padding-bottom: cqw-size(10px);
			background-color: #dedede;
			margin-bottom: cqw-size(18px);
			padding-left: cqw-size(11px);

			&:first-child {
				width: cqw-size(134px);
			}
		}

		&__td {
			text-align: center;
			background-color: #f1f1f1;
			width: cqw-size(92px);
		}
	}

	.payment-total-wrapper {
		display: flex;
		justify-content: flex-end;
		margin-top: cqw-size(25px);
	}

	.payment-total {
		&__th,
		&__td {
			font-size: cqw-size(11px);
			line-height: cqw-size(11px);
			font-weight: 700;
			width: cqw-size(114px);
			text-align: center;
			padding-top: cqw-size(10px);
			padding-bottom: cqw-size(10px);
			border-bottom: cqw-size(4px) solid color(white);
		}

		&__th {
			background-color: #dedede;
		}

		&__td {
			background-color: #f1f1f1;
		}
	}

	.paystub-base-metrics__item {
		font-style: italic;
		font-size: cqw-size(14px);
		line-height: cqw-size(12px);
		text-transform: uppercase;
	}
}

.pst5--new.pst5--green {
	.top__body,
	.earnings-total__td,
	.deductions-total__td,
	.payment-total__td {
		background-color: #70dd7f;
	}

	.employee__th,
	.earnings__th,
	.deductions__th,
	.earnings-total__th,
	.deductions-total__th,
	.payment-total__th {
		background-color: #38b449;
		color: #fff;
	}
}

.pst5--new.pst5--blue {
	.top__body,
	.earnings-total__td,
	.deductions-total__td,
	.payment-total__td {
		background-color: #90aeff;
	}

	.employee__th,
	.earnings__th,
	.deductions__th,
	.earnings-total__th,
	.deductions-total__th,
	.payment-total__th {
		background-color: #4673ec;
		color: #fff;
	}
}
