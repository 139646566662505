@import './variables';
@import '../../node_modules/bootstrap/scss/functions';

@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/vendor/rfs';

@import '../../node_modules/bootstrap/scss/utilities';

@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/containers';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/reboot';
@import './custom-bootstrap-type';

:root {
	@each $color, $value in $colors {
		--#{$variable-prefix}#{$color}-rgb: #{to-rgb($value)};
	}
}

// disable all utilities except those required
$utilities: map-merge(
	$utilities,
	(
		// 'align': null,
		'float': null,
		'opacity': null,
		'overflow': null,
		// 'display': null,
		'shadow': null,
		'position': null,
		'top': null,
		'bottom': null,
		'start': null,
		'end': null,
		'translate-middle': null,
		// 'border': null,
		// 'border-top': null,
		// 'border-end': null,
		'border-bottom': null,
		// 'border-start': null,
		'border-color': null,
		'border-width': null,
		'width': null,
		'max-width': null,
		'viewport-width': null,
		'min-viewport-width': null,
		'height': null,
		'max-height': null,
		'viewport-height': null,
		'min-viewport-height': null,
		'flex': null,
		'flex-direction': null,
		'flex-grow': null,
		'flex-shrink': null,
		'flex-wrap': null,
		'gap': null,
		'justify-content': null,
		'align-items': null,
		'align-content': null,
		'align-self': null,
		// 'order': null,
		// 'margin': null,
		// 'margin-x': null,
		// 'margin-y': null,
		// 'margin-top': null,
		// 'margin-end': null,
		// 'margin-bottom': null,
		'margin-start': null,
		'negative-margin': null,
		'negative-margin-x': null,
		'negative-margin-y': null,
		'negative-margin-top': null,
		'negative-margin-end': null,
		'negative-margin-bottom': null,
		'negative-margin-start': null,
		// 'padding': null,
		// 'padding-x': null,
		// 'padding-y': null,
		// 'padding-top': null,
		// 'padding-end': null,
		// 'padding-bottom': null,
		// 'padding-start': null,
		'font-family': null,
		// 'font-size': null,
		'font-style': null,
		// 'font-weight': null,
		'line-height': null,
		'text-align': (
			responsive: true,
			property: text-align,
			class: ta,
			values: (
				left: left,
				right: right,
				center: center,
			),
		),
		'text-decoration': null,
		// 'text-transform': null,
		'white-space': null,
		'word-wrap': null,
		'color': null,
		'text-opacity': null,
		'background-color': null,
		'bg-opacity': null,
		'gradient': null,
		'user-select': null,
		'pointer-events': null,
		'rounded': null,
		'rounded-top': null,
		'rounded-end': null,
		'rounded-bottom': null,
		'rounded-start': null,
		'visibility': null,
	)
);

@import '../../node_modules/bootstrap/scss/bootstrap-utilities';
