@import '../constants.scss';

.w2t2 {
  position: relative;
  width: 918px;
  font-family: 'Arial';
  color: #000;
  padding: indent(6) 56px;

    &__row {
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        border-bottom: 1px dotted #000;
      }
    }

    &__top-left {
      width: 403px;
      padding-right: 34px;
      padding-left: 5px;
      padding-bottom: 14px;
      border-right: 1px dotted #000;
      position: relative;
    }

    &__top-right {
      width: 403px;
      height: 100px;
      padding-left: 34px;
      padding-bottom: 14px;
      padding-right: 5px;
      position: relative;
    }

    &__bottom-left {
      width: 403px;
      padding: 10px 34px 14px 5px;
      border-right: 1px dotted #000;
      position: relative;
    }

    &__bottom-right {
      width: 403px;
      padding: 50px 5px  14px 34px;
      position: relative;
    }

    .top-left {
      &__footer {
        margin-top: 4px;
      }

      &__watermark {

        .watermark-preview {
          width: 328px;
          margin-top: 47px;
          margin-left: -14px;
          top: 0;
        }
      }
    }

    .top-right {

      &__watermark {

        .watermark-preview {
          width: 328px;
          margin-top: 47px;
          margin-left: 14px;
          top: 0;
        }
      }
    }

    .bottom-left {
      &__watermark {

        .watermark-preview {
          width: 328px;
          margin-top: 98px;
          margin-left: -14px;
          top: 0;
        }
      }
    }

    .bottom-right {
      &__watermark {

        .watermark-preview {
          width: 328px;
          margin-top: 98px;
          margin-left: 14px;
          top: 0;
        }
      }
    }

    .base-table {
      width: 100%;
      font-size: 8px;
      line-height: 8px;
      border: 1px solid #000;

      .table-point {
        &__marker {
          font-size: 8px;
          height: 8px;
          margin-top: -2px;
          margin-right: 4px;
        }

        &__item {
          &:not(:last-child) {
            margin-bottom: 5px;
          }

          &--center {
            margin-left: -15px;
          }
        }
      }

      .strong-cell {
        border: 1px solid #000;
        padding: 3px 0 3px 3px;
      }

      &__name {
        width: 200px;
      }

      &__tr {
        border-bottom: 1px solid #000;
      }

      &__td {
        padding: 3px 0 5px 3px;
        border-right: 1px solid #000;
      }

      &__name-cell {
        font-size: 10px;
        line-height: 13px;
        width: 254px;
      }

      &__document-cell {
        font-size: 9px;
        line-height: 13px;
        text-align: center;
        padding-top: 6.5px;
      }
    }

    .employee {
      width: 100%;

      &__tbody {
        vertical-align: top;
      }

      &__tr {
        &:not(:last-child) {
          border-bottom: 1px solid #000;
        }

        &--bb2back {
          &:not(:last-child) {
            border-bottom: 2px solid #000;
          }
        }

        &--bt2back {
          &:not(:last-child) {
            border-top: 2px solid #000;
          }
        }
      }

      &__td {
        height: 28.5px;
        padding: 3px 0 3px 3px;
        word-break: break-all;

        &:not(:last-child) {
          border-right: 1px solid #000;
        }

        &--left {
          width: 120px;
          border-right: 1px solid #000;
        }

        &--middle {
          width: 121px;
        }

        &--wide {
          padding-bottom: 20px;
        }
      }
    }

    .employee-high-cell {
      &__top, &__bottom {
        padding: 3px 0 3px 3px;
      }

      &__top {
        height: 42px;
        border-bottom: 1px solid #000;

        &--strong {
          border: 1px solid #000;
          border-bottom: 2px solid #000;
        }
      }
    }

    .states-info {
      display: flex;
      height: 28.5px;

      &__item {
        padding: 3px 0 3px 3px;

        &:not(:last-child) {
          border-right: 1px solid #000;
        }

        &:first-child {
          width: 43px;
        }

        &:nth-child(2) {
          width: 107px;
          padding-left: 0;
          text-align: center;
        }

        &:nth-child(3) {
          width: 107px;
        }

        &:nth-child(4) {
          width: 106px;

          .table-point__item {
            text-indent: 2px;
          }
        }
      }
    }

    .after-table-footer {
      font-family: 'Arial';
      padding-left: 8px;
      padding-right: 8px;

      &__top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;
      }

      &__bottom {
        font-weight: 400;
        font-size: 8px;
        line-height: 8px;
      }

      &__item {

        font-size: 8px;
        line-height: 8px;

        &:first-child {
          width: 40%;
          font-weight: 700;
          margin-top: 3px;
        }

        &:nth-child(2) {
          width: 30%;
          font-weight: 700;
          font-size: 11px;
          line-height: 13px;
          text-align: center;
          text-indent: -34px;
        }

        &:nth-child(3) {
          width: 30%;
          font-weight: 400;
          text-align: right;
          margin-top: 3px;
        }
      }
    }

    .before-table-head {
      font-size: 9px;
      line-height: 12px;
      margin-bottom: 4px;
      padding-left: 8px;
      padding-right: 8px;
    }
}
