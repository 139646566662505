@import '../constants.scss';

.invoice5 {
	color: #000;
	display: flex;
	padding: 0;

	--accent-light: #ddd;
	--accent: #888;

	&.green {
		--accent-light: #8cda88;
		--accent: #5fb157;
	}

	&.blue {
		--accent-light: #96adf9;
		--accent: #5072e4;
	}

	.accent-light {
		color: var(--accent-light);
	}

	.accent {
		color: var(--accent);
	}


	&__inner {
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}

	.preview {
		.watermark-preview {
			width: 85%;
		}
	}

	&__header {
		position: relative;
		padding: 82px 85px 85px 50px;
		gap: 60px;
		margin-bottom: 55px;
		flex-wrap: wrap;
		display: flex;
		align-items: center;
		color: color(white);
		background: var(--accent);
	}

	.header {
		&__figure {
			bottom: -71px;
			right: 0;
			position: absolute;
		}

		&__title,
		&__total {
			z-index: 9;
			line-height: 1.5;
			font-size: 48px;
		}

		&__title {
			font-weight: 400;
			max-width: 50%;
			word-break: break-word;
		}

		&__total {
			font-weight: 900;
		}
	}

	&__body {
		font-size: 14px;
		padding-left: 50px;
		margin-bottom: 90px;
	}

	.body {
		&__main {
			display: flex;
			gap: 50px;
		}

		&__row {
			display: flex;
			width: 100%;
		}
		&__header {
			display: flex;
			align-items: center;
			gap: 50px;
			margin-bottom: 72px;
		}

		&__logo {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 210px;

			.logo {
				height: 71px;
			}

			&--placeholder {
				width: 100%;
				text-align: center;
				color: white;
				padding: 18px 20px 20px 20px;
				text-transform: uppercase;
				font-weight: 700;
				font-size: 22px;
				background: var(--accent);
			}
		}

		&__details {
			display: flex;
			gap: 84px;
		}

		.details {
			display: flex;
			flex-direction: column;
			gap: 3px;

			&__name {
				color: #666666;
			}

			.black {
				color: color(black);
			}
		}

		&__info {
			width: 210px;
			display: flex;
			flex-direction: column;
			gap: 55px;
		}

		.info {
			&__header {
				font-size: 14px;
				padding: 10px;
				background: var(--accent-light);
				font-weight: 700;
				text-transform: none;
				margin-bottom: 24px;
			}

			a {
				color: inherit;
			}

			span {
				color: #666666;
			}
		}

		.table {
			border-bottom: 1px solid var(--accent-light);
			margin-bottom: 15px;

			&__head {
				color: var(--accent);
			}

			&__cell--th {
				width: 74px;
				padding: 10px 0;

				&:last-child {
					width: 154px;
				}
			}

			&__cell--first {
				width: 320px;
			}

			&__row {
				&:first-child {
					padding-top: 13px;
				}
			}

			&__cell {
				padding: 10px 10px 20px 0;
			}

			&__title {
				font-size: 14px;
				display: inline;
			}

			&__description {
				color: #666666;
			}
		}

		.total {
			display: flex;
			flex-direction: column;
			gap: 9px;
			margin-bottom: 60px;
			padding-right: 79px;
			font-size: 14px;

			&__item {
				display: flex;
				justify-content: flex-end;
				width: 100%;
			}

			&__name {
				margin-right: 30px;
				color: #666;
			}

			&__value {
				min-width: 74px;
			}
		}

		.grand-total {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			font-size: 16px;
			margin-bottom: 44px;

			&__title {
				font-weight: 700;
				padding-right: 30px;
			}

			&__value {
				font-weight: 700;
				min-width: 74px;
				padding-right: 5px;
			}
		}
	}

	&__footer {
		margin-top: auto;
		padding: 27px 59px 55px 283px;
		color: white;
		background: var(--accent-light);
		font-size: 14px;
		position: relative;
		min-height: 105px;

		.figure {
			&-left {
				position: absolute;
				top: 0;
				left: -3.9%;
			}
			&-top {
				position: absolute;
				top: -48%;
				left: 0%;
			}
		}
	}
}
