@import 'src/styles/constants';

.heading {
	text-transform: uppercase;
	color: color(white);
	margin-bottom: indent(1);

	@include md-up {
		margin-bottom: indent(2);
	}
}
