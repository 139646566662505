@import 'src/styles/constants';

.item-stub {
	background-color: color(white);
	border-radius: 10px;
	border: 1px solid color(blue400);
	text-align: center;

	&__link {
		padding: 15px 20px;
		display: block;
		text-transform: uppercase;
		color: color(blue400);
		font-weight: 700;

		&:last-child {
			@include md-up {
				padding-right: 19px;
				padding-left: 19px;
			}

			@include lg-up {
				padding-right: 20px;
				padding-left: 20px;
			}
		}

		&:hover {
			color: color(orange500);
		}
	}

	&__link--active {
		color: color(orange500);
	}
}
