@import 'src/styles/constants';

.menu {
	@include reset-list();

	&__item {
		&:not(:last-child) {
			margin-bottom: indent(2);
		}
	}

	&__link {
		text-transform: uppercase;
		color: color(blue400);
		font-size: 16px;
		line-height: 24px;

    &:hover, &--active {
      color: color(orange500);
    }
	}
}

.log-out {
	border:none;
	background-color: transparent;
  padding: 0;
}