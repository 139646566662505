@import '../constants.scss';

.invoice4 {
	--gray: #999;
	--blue: #5072e4;
	--green: #5fb157;

	--theme-color: var(--gray);

	&.green {
		--theme-color: var(--green);
	}
	&.blue {
		--theme-color: var(--blue);
	}

	color: #000;
	display: flex;
	flex-direction: column;

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		margin-bottom: 37px;
	}

	.header {
		&__title {
			color: white;
			background: color(black);
			padding: 3px 6px;
			max-width: 50%;
			word-wrap: break-word;

			.title {
				font-size: 32px;
			}
		}

		&__details {
			font-size: 14px;
			display: flex;
			gap: 84px;
		}
	}

	.details {
		display: flex;
		flex-direction: column;
		gap: 6px;

		&__name {
			color: #666;
		}

		.black {
			color: color(black);
		}
	}

	&__info {
		font-size: 14px;
		margin-bottom: 42px;
	}

	.info {
		&__row {
			display: flex;
			gap: 227px;
		}

		.gray {
			color: #666666;
		}

		.black {
			color: color(black)
		}

		a {
			color: #666;
		}

	}

	&__table {
		border-bottom: 1px solid #ddd;
		margin-bottom: 20px;
	}

	.table {
		&__head {
			background: var(--theme-color);
			color: color(white);
		}

		&__cell--th {
			width: 160px;
			padding: 10px 20px;
			border-left: 2px solid #ddd;
		}

		&__cell--first {
			border-left: none;
			width: 400px;
		}

		&__cell--td {
			padding: 10px 20px;

			.table__title {
				display: inline;
			}

			.table__description {
				font-size: 14px;
				color: #666666;
			}
		}
	}

	&__total {
		display: flex;
		flex-direction: column;
		gap: 5px;
		margin-bottom: 60px;
		padding-right: 66px;
	}

	.total {
		font-size: 16px;

		&__item {
			display: flex;
			justify-content: flex-end;
			width: 100%;
		}

		&__name {
			margin-right: 30px;
			color: #666;
		}

		&__value {
			min-width: 74px;
		}
	}

	.grand-total {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-bottom: 44px;

		&__title {
			font-weight: 700;
			font-size: 16px;
			padding-right: 30px;
		}
		&__value {
			color: color(white);
			font-weight: 700;
			background: color(black);
			padding: 12px 35px;
		}
	}

	&__footer {
		margin-top: auto;
		display: flex;
		align-items: center;
	}

	.footer {
		&__logo {
			display: flex;
			align-items: center;
			height: 105px;
			width: 180px;
			font-size: 22px;
			opacity: 0.33;
			margin-right: 122px;

			&-placeholder {
				color: color(white);
				text-transform: uppercase;
				padding: 10px 30px;
				background: #999;
			}
		}
		&__notes {
			color: #666666;
			font-size: 14px;
		}
	}
}
