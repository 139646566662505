@import 'src/styles/constants';
.nav-wrapper {
	background: $gradient-header;

	@include md-up {
		padding-top: indent(1);
		padding-bottom: indent(1);
	}

	@include xl-up {
		padding-top: indent(2);
		padding-bottom: indent(2);
	}
}

.menu-stub {
	@include reset-list();
	display: flex;
	justify-content: center;

	&__item {
		&:not(:last-child) {
			margin-right: 27px;

			@include lg-up {
				margin-right: 37px;
			}

			@include xl-up {
				margin-right: indent(5);
			}
		}

		&:nth-last-child(2) {
			margin-right: 28px;

			@include lg-up {
				margin-right: 38px;
			}

			@include xl-up {
				margin-right: indent(5);
			}
		}
	}
}
