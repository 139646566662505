.invoice3 {
	--gray: #666;
	--gray-light: #f4f4f4;
	--blue: #5072e4;
	--blue-light: #96adf9;
	--green: #5fb157;
	--green-light: #8cda88;

	--text-dark: #000;
	--text-light: #666;

	// theme colors
	--theme-color: var(--gray);
	--theme-color-light: var(--gray-light);

	&.green {
		--theme-color: var(--green);
		--theme-color-light: var(--green-light);
	}
	&.blue {
		--theme-color: var(--blue);
		--theme-color-light: var(--blue-light);
	}

	overflow: hidden;
	position: relative;
	display: flex;
	flex-direction: column;

	&__content {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}

	.bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: -1;

		&__big {
			position: absolute;
			top: 22.44%;
			left: -443px;

			width: 731px;
			height: 731px;
			flex-shrink: 0;
			border-radius: 731px;
			background: var(--theme-color-light);
		}

		&__small {
			position: absolute;
			bottom: -60px;
			right: -61px;

			width: 383px;
			height: 383px;
			flex-shrink: 0;
			border-radius: 383px;
			background: var(--theme-color-light);
		}
	}

	.logo {
		&--dash {
			border: 1px dashed var(--text-light);
		}

		&--bg {
			.logo__content {
				background: var(--theme-color);
			}
		}
		&__content {
			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
			width: 89px;
			height: 89px;
			flex-shrink: 0;
			border-radius: 89px;
			overflow: hidden;
		}

		&__placeholder {
			color: #fff;
			text-align: center;
			font-size: 22px;
			font-family: Lato;
			font-style: normal;
			font-weight: 700;
			line-height: 26px;
		}
	}

	.header {
		display: flex;
		align-items: flex-start;
		gap: 49px;
		padding-bottom: 92px;

		&__logo {
			width: 100px;
			height: 100px;
			border-radius: 99px;
			background: #fff;
			display: flex;
			justify-content: center;
			align-items: center;

		}

		&__content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 13px;
			flex-grow: 1;
		}

		&__top {
			display: flex;
			align-items: center;
			gap: 30px;
			align-self: stretch;
			width: 100%;
			justify-content: space-between;
		}

		&__separator {
			height: 0px;
			flex-shrink: 0;
			align-self: stretch;
			border: 1px solid #dddddd;
			margin: 0;
		}

		&__info {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 12px;
		}
	}

	.top {
		color: var(--text-light);
		font-size: 14px;
		font-family: Lato;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		&__title {
			color: var(--text-dark);
			font-size: 40px;
			font-family: Lato;
			font-style: normal;
			font-weight: 700;
			line-height: 1;
		}

		&__right {
			display: flex;
			justify-content: flex-end;
			gap: 30px;
			flex-grow: 1;
		}
	}

	.details {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 5px;

		&__item {
			display: flex;
		}
		&__name {
			margin-right: 4px;
		}
		&__value {
			color: var(--text-dark);
			white-space: nowrap;
		}
	}

	.info {
		color: #000;
		font-size: 14px;
		font-family: Lato;
		font-style: normal;
		font-weight: 700;
		line-height: 20px;
		&__item {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 12px;
		}

		&__title {
			font-weight: 700;
			color: var(--text-dark);
		}

		&__name {
			font-weight: 400;
			color: var(--text-dark);
		}

		&__address {
			color: var(--text-light);
			margin: 0;
		}
		a {
			color: var(--text-light);
		}
	}

	.table {
		&__cell {
			padding: 10px 10px 10px 20px;
			width: 161px;

			color: var(--text-dark);
			font-size: 16px;
			font-family: Lato;
			font-style: normal;
			font-weight: 400;
			line-height: 120%;
			letter-spacing: 0.16px;
			text-align: left;
		}
		&__cell--th {
			padding: 10px 20px;
			font-weight: 700;
			color: var(--text-dark);
			border-left: 2px solid #ddd;

			font-size: 18px;
			&:first-child {
				border-left: none;
			}
		}

		&__cell--first {
			width: 45.4%;
		}

		&__description {
			color: var(--text-light);
		}
	}

	.total {
		display: flex;
		flex-direction: column;
		gap: 12px;
		margin-top: 20px;

		color: var(--text-light);
		font-size: 14px;
		font-family: Lato;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		letter-spacing: 0.14px;

		&__item {
			display: flex;
			padding-right: 0px;
			justify-content: flex-end;
			align-items: center;
			gap: 30px;
		}
		&__name {
			width: 100%;
			text-align: right;
		}

		&__value {
			min-width: 140px;
			text-align: left;
			color: var(--text-dark);
		}
	}

	.grand-total {
		display: flex;
		padding-right: 40px;
		padding-top: 53px;
		margin-bottom: 179px;
		justify-content: flex-end;
		gap: 27px;

		color: var(--text-dark);
		text-align: right;
		font-size: 22px;
		font-family: Lato;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		letter-spacing: 0.22px;
	}

	.notes {
		width: 561px;
		color: #777;
		margin-top: auto;

		font-size: 14px;
		font-family: Lato;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		letter-spacing: 0.14px;
	}
}
