@import '../constants.scss';

.pst6 {
  width: 918px;
  position: relative;
  font-family: 'Arial';
  color: #000;
  padding: indent(6) 61px;

  &__watermark {

    .watermark-preview {
      width: 505px;
      margin-top: 72px;
      top: 0;
    }
  }

  .top {
    &__head {
      display: flex;
      justify-content: space-between;
    }

    &__body {
      padding: 24px 35px 24px 35px;
      background: #F1F1F1;
    }
  }

  .top-head {

    &__left{
      font-size: 14px;
      line-height: 12px;
      text-transform: uppercase;
      margin: 6px 0 0 12px;
      font-style: italic;
    }

    &__company-name {
      margin-bottom: 6px;
    }

    &--inline {
      display: block;
      margin-top: 6px;
    }
  }

 .head-right {
   margin-right: 62px;

    &__title {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 6px;
    }

    &__dates {
      font-weight: 400;
      font-size: 11px;
      line-height: 11px;
    }

    &__item {
      margin-bottom: 6px;
    }
  }

  .top-body {
    margin-top: 11px;
    display: flex;
    justify-content: space-between;

    &__employee {
      width: 385px;
      margin-left: auto;
      font-style: italic;
      font-weight: 700;
      font-size: 13px;
      line-height: 12px;
      text-transform: uppercase;
    }

    &__social {
      width: 240px;
      font-weight: 700;
      font-size: 13px;
      line-height: 12px;
      text-transform: uppercase;

      span {
        font-weight: normal;
      }
    }
  }

  .body-employee {
    &__item {
      margin-bottom: 6px;
    }
    &--inline {
      display: block;
      margin-top: 6px;
    }
  }

  &__middle {
    margin-top: 25px;
  }

  .middle {

    &__earnings {
      width: 100%;
    }
  }

  .earnings {
    width: 100%;
    margin-bottom: 27px;

    &__th, &__td {
      font-size: 11px;
      line-height: 11px;
      padding-left: 12px;
      padding-right: 12px;

      &:nth-last-child(1), &:nth-last-child(2) {
        text-align: right;
      }
    }

    &__th {
      padding-top: 11px;
      padding-bottom: 11px;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;

      &:first-child {
        width: 219px;
      }

      &:nth-child(2) {
        width: 145px;
      }

      &:nth-child(3) {
        width: 149px;
      }
    }

    &__tr {
      vertical-align: baseline;
      &:last-child {
        height: 55px;
      }
    }

    &__td {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .earnings-tfoot, .deduction-tfoot {
     &__td {
      font-size: 11px;
      line-height: 11px;
      padding-left: 12px;
      padding-right: 12px;
      background: #F1F1F1;
      padding-top: 12px;
      padding-bottom: 12px;
      font-weight: bold;

      &:nth-last-child(1), &:nth-last-child(2) {
        text-align: right;
      }
    }
  }

  .deduction {
    width: 100%;
    margin-bottom: 25px;

    &__tbody {
      vertical-align: baseline;

      &::before {
        content: '';
        display: block;
        height: 10px;
      }
    }

    &__th, &__td {
      font-size: 11px;
      line-height: 11px;
      padding-left: 12px;
      padding-right: 12px;

      &:nth-last-child(1), &:nth-last-child(2) {
        text-align: right;
      }
    }

    &__th {
      padding-top: 11px;
      padding-bottom: 11px;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;

      &:first-child {
        width: 219px;
      }

      &:nth-child(2) {
        width: 297px;
      }
    }

    &__tr {
      vertical-align: baseline;
      &:last-child {
        height: 42px;
      }
    }

    &__td {
      padding-bottom: 10px;
    }
  }

  .deduction-conclusion {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    margin-left: 6px;
  }

  &__bottom {
    margin-top: 25px;
  }

  .bottom {
    &__cut-line {
      width: 100%;
      height: 19px;
      position: relative;
      margin-bottom: 36px;

      &::before {
        position: absolute;
        right: 0;
        top: 50%;
        content: '';
        display: block;
        width: calc(99% - 24px);
        height: 1px;
        background-image: linear-gradient(to right, rgb(92, 92, 92) 33%, rgba(255,255,255,0) 0%);
        background-position: bottom;
        background-size: 12px 1px;
        background-repeat: repeat-x;
        transform: translateY(-50%);
      }
    }

    &__order {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 28px;
    }

    &__employee-name {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      margin-left: 12px;
      margin-bottom: 14px;
    }

    &__payment {
      width: 100%;
    }

    &__signature {
      width: 312px;
      margin-left: auto;
      font-size: 11px;
      line-height: 11px;
      padding-top: 15px;
      border-top: 1px solid #000;
    }
  }

  .bottom-order {
    &__company {
      font-size: 14px;
      line-height: 12px;
      text-transform: uppercase;
      margin-left: 12px;
      font-style: italic;
    }

    &__company-name {
      margin-bottom: 6px;
    }
  }

  .bottom-dates {
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;
    margin-right: 90px;

    &__item {
      margin-bottom: 5px;
    }
  }

  .bottom-payment {
    margin-bottom: 89px;

    &__th, &__td {
      font-size: 11px;
      line-height: 11px;
      font-weight: 700;
      padding: 12px;
    }

    &__th {
      width: 562px;
      background-color: #F1F1F1;
    }

    &__td {
      text-align: right;
      background-color: #DEDEDE;
    }
  }

  &--green {
    .top__body,
    .earnings__th,
    .earnings-tfoot__td,
    .deduction-tfoot__td,
    .bottom-payment__th {
        background-color: #70dd7f;
        border-top: 1px solid #70dd7f;
        border-bottom: 1px solid #70dd7f;
      }
      
    .deduction__th {
      border-top: 1px solid #70dd7f;
      border-bottom: 1px solid #70dd7f;
    }  

    .bottom__signature {
      border-top: 1px solid #70dd7f;
    }
    
    .bottom-payment__td {
      background-color: #38B449;
      color: #fff
    }
  }

  &--blue {
    .top__body,
    .earnings__th,
    .earnings-tfoot__td,
    .deduction-tfoot__td,
    .bottom-payment__th {
        background-color: #90aeff;
        border-top: 1px solid #90aeff;
        border-bottom: 1px solid #90aeff;
      }
      
    .deduction__th {
      border-top: 1px solid #90aeff;
      border-bottom: 1px solid #90aeff;
    }  

    .bottom__signature {
      border-top: 1px solid #90aeff;
    }
    
    .bottom-payment__td {
      background-color: #4673EC;
      color: #fff
    }
  }

}
