@import 'src/styles/constants';

.logo {
	transition: $transition300ease;

	&:hover {
		opacity: 0.7;
		transition: $transition300ease;
	}
}
