.invoice6 {
	--gray: #666;
	--gray-light: #f4f4f4;
	--blue: #5072e4;
	--blue-light: #96adf9;
	--green: #5fb157;
	--green-light: #8cda88;

	--text-dark: #000;
	--text-light: #666;

	// theme colors
	--theme-color: var(--gray);
	--theme-color-light: var(--gray-light);

	&.green {
		--theme-color: var(--green);
		--theme-color-light: var(--green-light);
	}
	&.blue {
		--theme-color: var(--blue);
		--theme-color-light: var(--blue-light);
	}
	--bd-radius: 24px;

	overflow: hidden;
	position: relative;
	background: var(--theme-color);
	display: grid;
	grid-template-columns: 210px 1fr;
	grid-gap: 20px;
	grid-template-rows: 200px 1fr;
	color: #000;

	&__header {
		grid-column: 1/3;
		background: #fff;
		border-radius: var(--bd-radius);
		padding: 20px 55px;
	}

	&__aside {
		background: #fff;
		border-radius: var(--bd-radius);
		padding: 15px;
	}

	&__content {
		background: #fff;
		border-radius: var(--bd-radius);
		padding: 10px;
	}

	&__notes {
		grid-column: 1/3;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;

		font-family: Lato;
		font-style: normal;
		font-weight: 700;

		&__logo-block {
			display: flex;
			width: 100px;
			height: 100px;

			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			border-radius: 22px;
			overflow: hidden;
		}

		&__logo-block--bg {
			background: #e3e3e3;
		}

		&__logo-placeholder {
			color: #000;
			text-align: center;
			font-size: 22px;
			line-height: 11.5px;
			text-transform: uppercase;
		}

		&__title {
			color: #000;
			font-size: 64px;
			text-transform: uppercase;
			display: inline-flex;
			flex-direction: column;
			
		}
		
		&__over-text{
			font-size: 32px;
		}

		&__number-mark {
			color: var(--theme-color);
			font-size: 222px;
			line-height: 11.5px;
			text-transform: uppercase;
		}

		&__number {
			color: #000;
			font-size: 32px;
			font-style: italic;
			font-weight: 700;
			line-height: 20px;
		}
	}

	.aside {
		display: flex;
		flex-direction: column;
		gap: 40px;
		align-items: flex-start;

		color: var(--text-dark);
		font-size: 14px;
		font-family: 'Lato-Regular', Helvetica;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;

		a{
			color: var(--text-light);
			text-decoration: none;
		}

		&__item {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			width: 100%;
		}

		&__title {
			width: 100%;
			padding: 13px 0px;
			text-align: center;

			font-family: 'Lato-Bold', Helvetica;
			font-weight: 700;
			letter-spacing: 0;
			white-space: nowrap;
		}

		&__terms {
			margin-top: -1px;
			width: fit-content;
		}

		&__info {
			letter-spacing: 0;
		}
		&__gray {
			color: #666666;
		}

		&__black {
			color: #000000;
		}
	}

	.table {
		border-bottom: 1px solid var(--theme-color-light);
		&__cell {
			padding: 10px 10px 10px 19px;
			width: 161px;

			font-size: 14px;
			font-family: 'Lato', sans-serif;
			font-style: normal;
			font-weight: 400;
			line-height: 120%;
			letter-spacing: 0.14px;
		}

		&__cell--th {
			padding: 16px 5px 12px;
			font-weight: 700;
			color: var(--theme-color);
			border-bottom: 1px solid var(--theme-color-light);

			&:first-child {
				border-left: none;
			}
		}

		&__cell--amount {
			text-align: center;
		}

		&__cell--first {
			width: 51.67%;
		}

		&__description {
			color: var(--text-light);
		}
	}

	.total {
		display: flex;
		justify-content: center;
		margin: 50px 0;
		&__inner {
			display: flex;
			flex-direction: column;
			gap: 12px;

			padding: 30px 20px;
			border-radius: 22px;
			background: #f4f4f4;

			color: var(--text-light);
			font-size: 14px;
			font-family: Lato;
			font-style: normal;
			font-weight: 400;
			line-height: 120%;
			letter-spacing: 0.14px;
		}

		&__item {
			display: flex;
			padding-right: 0px;
			justify-content: space-between;
			align-items: center;
			gap: 30px;
		}
		&__name {
			width: 96px;
			text-align: left;
		}

		&__value {
			text-align: left;
			color: var(--text-dark);
		}
	}

	.grand-total {
		display: flex;
		justify-content: center;
		gap: 27px;
		margin: 50px 0;

		color: #000;
		font-size: 32px;
		font-family: Lato;
		font-style: normal;
		font-weight: 700;
		line-height: 120%;
		letter-spacing: 0.32px;
	}

	.notes {
		color: #fff;
		font-size: 14px;
		font-family: Lato;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		letter-spacing: 0.14px;
	}
}
