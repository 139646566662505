@import '../constants.scss';

.w2t1 {
  width: 918px;
  position: relative;
  font-family: 'Arial';
  color: #000;
  padding: indent(6) 61px;

  &__watermark {

    .watermark-preview {
      width: 328px;
      margin-top: 95px;
      top: 0;
    }
  }

  &__table {
    width: 100%;
    border: 1px solid #000;
  }


  .w2 {
    font-size: 10px;
    line-height: 11px;

    &__tbody {
      vertical-align: top;
    }

  }

  .tbody {
    &__head-td {
      padding: 4px 4px 11px 4px;
      border-right: 1px solid #000;
      border-bottom: 1px solid #000;

      &:first-child {
        width: 185px;
      }

      &:nth-child(2) {
        width: 212px;
      }

      &:nth-child(3) {
        font-size: 14px;
        line-height: 18px;
      }

      &__td-wide {
        padding: 0;
      }
    }
  }

  .employees {
    width: 100%;

    &__tbody {
      vertical-align: baseline;
    }

    &__tr {
      border-bottom:1px solid #000;
    }

    &__td {
      padding: 3px 4px 7px 4px;

      &--left, &--middle {
        border-right: 1px solid #000;
        height: 39.5px;
      }

      &--left {
        width: 431px;
      }

      &--middle {
        width: 181px;
      }
    }
  }

  .checkboxes {
    display: flex;
    justify-content: space-between;
    margin-top: -4px;
    margin-bottom: -16px;

    &__item {
      width: 40px;
    }
  }

  .checkbox {
    &:last-child {
      margin-right: 5px;
    }

    &__label {
      font-size: 8px;
      line-height: 9px;
      display: block;
      margin-bottom: -14px;
    }

    &__item {
      width: 12px;
      height: 12px;
      margin-top: 15px;
    }
  }

  .common {
    width: 100%;

    &__top {
      vertical-align: top;
    }

    &__td {
      padding: 3px 2px 7px 4px;
      &:not(:last-child) {
        border-right: 1px solid #000;
      }

      &:first-child {
        width: 66px;

        .table-point__item--center {
          margin-left: -17px;
        }
      }

      &:nth-child(2) {
        width: 141px;
      }

      &:nth-child(3) {
        width: 127px;

        .table-point__item--center {
          margin-left: -19px;
        }
      }

      &:nth-child(4) {
        width: 120px;

        .table-point__item--center {
          margin-left: -19px;
        }
      }

      &:nth-child(5) {
        width: 126px;
      }

      &:nth-child(6) {
        width: 120px;
      }
    }

    &__tbody {
      vertical-align: top;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
  }

  .bottom {
    font-size: 10px;
    line-height: 11px;
    margin-top: 3px;

    &__left {
      padding-left: 10px;
    }
  }

  .bottom-left, .bottom-middle, .bottom-right {
    width: 33%;
  }

  .bottom-left {
    &__top {
      display: flex;
      align-items: flex-start;
      margin-bottom: 8px;
    }

    &__name {
      font-size: 24px;
      line-height: 28px;
      display: inline-block;
      margin-left: 14px;
      margin-right: 12px;
    }

    &__description {
      width: 111px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      margin-top: 2px;
    }
  }

  .bottom-middle {
    text-align: center;

    &__year {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      text-indent: -5px;
      margin-top: 2px;
    }
  }

  .bottom-right {
    display: flex;
    justify-content: flex-end;

    &__description {
      font-size: 10px;
      line-height: 14px;
      width: 252px;
      text-align: center;
    }
  }
}

