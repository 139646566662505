@import '../constants.scss';

.form1099t1 {
  width: 918px;
  position: relative;
  font-family: 'Arial';
  color: #000;
  padding: indent(6) 61px;

  &__watermark {

    .watermark-preview {
      width: 508px;
      margin-top: 50px;
      top: 0;
    }
  }

  .invoice {
    width: 100%;
    background-color: #F3F3F3;
    border: 1px solid #000;
    font-size: 12px;
    line-height: 14px;

    &__tbody {
      vertical-align: top;
    }

    &__td{
      padding: 8px 4px;

      &--left, &--middle-left, &--middle-right {
        border-right: 1px solid #000;
      }

      &--left {
        width: 356px;
      }

      &--middle-left {
        width: 160px;
      }

      &--middle-right {
        width: 141px;
      }

      &--tall {
        height: 86px;
      }

    }

    &__tr {
      &:not(:last-child) {
        border-bottom: 1px solid #000;
      }
    }

    &__tfoot {
      border-top: 1px solid #000;
    }
  }

  .corrected {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    label {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 8px;
    }

    &__item {
      & + div {
        --size: 16px;
        margin-top: 0;
      }

      & + div + p {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .invoice-rent {

    &__top, &__bottom {
      padding: 8px 4px 0 4px;
    }

    &__top {
      border-bottom: 1px solid #000;
    }
  }

  .rent-bottom {
      &__last-item {
        margin-bottom: 4px;
      }
  }

  .stroked {
    text-align: center;

    &__year {
      font-size: 38px;
      margin-top: 18px;
      margin-bottom: 20px;
      font-weight: 700;
    }

    &__text {
      color: black;
      -webkit-text-fill-color: white;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: black;
    }
  }

  .other-income {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    vertical-align: middle;
  }

  .solid-cell {
    border: 1px solid #000;
    padding: 6px 4px;
  }

  .recipient-copy {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    padding-right: 8px;
    position: relative;
    top: 5px
  }

  .tins {
    display: flex;
    height: 100%;

    &__left {
      width: 178px;
      border-right: 1px solid #000;
    }

    &__right {
      width: 177px;
    }

    &__left, &__right {

      padding: 8px 4px;
      display: inline-block;
    }
  }

  .form1099-checkbox-input {
    width: 16px;
    height: 16px;
    border: 1px solid rgba(9, 32, 93, 0.3);
    border-radius: 2px;
  }

  .more-checkbox-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: -23px;
    margin-right: 5px;
  }

  .more-checkbox {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    margin-bottom: 5px;

    label {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .tax-information {
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    margin-right: 3px;
  }

  .fatca {
    display: flex;

    &__left, &__right {
      padding-top: 8px;
      padding-bottom: 4px;
    }

    &__left {
      width: 258px;
      padding-left: 3px;
    }

    &__right {
      border-left: 1px solid #000;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 76px;
    }

    &__text {
      text-align: center;
      margin-top: 6px;
      margin-bottom: 8px;
    }
  }

  .fatca-checkbox {
    width: 16px;

    label {

      display: block;
    }
  }

  .dashed-cell {

    &__top, &__bottom {
      min-height: 47px;
    }

    &__top {
      border-bottom: 1px dashed #000;
      padding: 8px 4px 0 4px;
    }

    &__bottom {
      padding: 10px 4px 0 4px;
    }
  }

  .tfoot {

    &__td {
      padding: 7px 0 6px 8px;
    }

    &__item {
      display: inline-block;

      &--left {
        width: 132px;
      }

      &--middle-left {
        width: 162px;
      }

      &--middle-right {
        width: 192px;
      }
    }
  }
}
