@import '../constants.scss';

.pst4 {
  width: 918px;
  position: relative;
  font-family: 'Arial';
  color: #000;
  padding: indent(6) 61px;

  &__base-table {
    width: 100%;
    border: 1px solid #DEDEDE;
  }

  .base-table {
      &__caption {
        padding: 0;
        display: flex;
        justify-content: flex-end;
      }
  }

  .caption {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;

    &__title {
      position: relative;
      background: linear-gradient(0deg, #3C3C3C 0%, rgba(60, 60, 60, 0) 164.15%);
      color: #fff;
      padding: 12px 11px 11px 76px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 51px 0 0 53px;
        border-color: transparent transparent transparent #ffffff;
      }
    }
  }

  .company {
    width: 100%;

    &__th, &__td{
      font-size: 14px;
      line-height: 18px;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 11px;
      text-transform: uppercase;
    }

    &__th{
      font-weight: 700;
      background-color: #DEDEDE;


      &:first-child {
        width: 262px;
      }
    }

    &__td {

      &:first-child {
        border-right: 1px solid #DEDEDE;
      }
    }
  }

  .employee {
    width: 100%;

    &__th, &__td{
      font-size: 14px;
      line-height: 18px;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 11px;
      text-transform: uppercase;
      &-transform {
        text-transform: none;
      }
    }

    &__th{
      font-weight: 700;
      background-color: #DEDEDE;

      &:first-child {
        width: 442px;
      }

      &:nth-child(2) {
        width: 176px;
      }

      &-small {
        &:first-child {
          width: 320px;
        }
      }
    }

    &__td {
      &:not(:first-child) {
        text-align: center;
      }

      &:not(:last-child) {
        border-right: 1px solid #DEDEDE;
      }
    }
  }

  .tables-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .payments {
    width: 100%;

    &__th, &__td{
      font-size: 14px;
      line-height: 18px;
      padding-top: 4px;
      padding-bottom: 4px;
      &-transform {
        text-transform: none;
      }
    }

    &__th{
      font-weight: 700;
      background-color: #DEDEDE;
      text-transform: uppercase;
      text-align: center;

      &:first-child {
        width: 184px;
      }

      &:nth-child(2) {
        width: 200px;
      }

      &:nth-child(3) {
        width: 117px;
      }
      &:nth-child(4) {
        width: 118px;
      }
    }

    &__td {
      text-align: center;
      &:first-child {
        padding-left: 11px;
      }
      &:not(:last-child) {
        border-right: 1px solid #DEDEDE;
      }
    }
  }

  .earnings {
    width: 50%;
    border-right: 1px solid #DEDEDE;

    &__tbody {
      vertical-align: baseline;
    }

    &__th, &__td {
      padding-left: 11px;
      padding-right: 11px;

      &:not(:first-child) {
        text-align: right;
      }
    }

    &__th {
      font-size: 14px;
      line-height: 18px;
      padding-top: 4px;
      padding-bottom: 4px;
      text-transform: uppercase;
      font-weight: 700;
      background-color: #DEDEDE;

      &:first-child {
        width: 117px;
      }
    }

    &__td {
      font-size: 13px;
      line-height: 18px;
      padding-top: 4px;
      padding-bottom: 4px;

      &:first-child {
        text-transform: uppercase;
      }
    }
  }

  .deduction {
    width: 50%;

    &__tbody {
      vertical-align: baseline;
    }

    &__th, &__td {
      padding-left: 11px;
      padding-right: 11px;

      &:not(:first-child) {
        text-align: right;
      }
    }

    &__th {
      font-size: 14px;
      line-height: 18px;
      padding-top: 4px;
      padding-bottom: 4px;
      text-transform: uppercase;
      font-weight: 700;
      background-color: #DEDEDE;

      &:first-child {
        width: 153px;
      }
    }

    &__td {
      font-size: 13px;
      line-height: 18px;
      padding-top: 4px;
      padding-bottom: 10px;

      &--last {
        padding-bottom: 4px;
      }


      &:first-child {
        text-transform: uppercase;
        padding-right: 0;
      }
    }

  }

  .total {
    width: 100%;

    &__th {
      &:first-child {
        width: 128px;
      }

      &:nth-child(2) {
        width: 138px;
      }

      &:nth-child(3) {
        width: 128px;
      }

      &:nth-child(4) {
        width: 131px;
      }

      &:nth-child(5) {
        width: 132px;
      }
    }

    &__th, &__td{
      font-size: 14px;
      line-height: 18px;
      padding-top: 4px;
      padding-bottom: 4px;
      text-transform: uppercase;
      text-align: center;
    }

    &__th{
      font-weight: 700;
      background-color: #DEDEDE;
    }

    &__td {
      &:not(:last-child) {
        border-right: 1px solid #DEDEDE;
      }
    }
  }

  &__watermark {

    .watermark-preview {
      width: 286px;
      margin-top: 43px;
      margin-left: -9px;
      top: 0;
    }
  }

  &--green {
    .caption {
      &__title {
        background: linear-gradient(0deg, #38B449 0%, rgba(56, 180, 73, 40%) 164.15%)
      }
    }

    .pst4 {
      &__base-table {
        border: 1px solid #70dd7f;
      }
    }
    
    .company__th,
    .employee__th,
    .payments__th,
    .earnings__th,
    .deduction__th,
    .total__th {
      background-color: #70dd7f;
      color: #fff;
    }
  }

  &--blue {
    .caption {
      &__title {
        background: linear-gradient(0deg, #4673EC 0%, rgba(70, 115, 236, 40%) 164.15%)
      }
    }

    .pst4 {
      &__base-table {
        border: 1px solid #97B2F9;
      }
    }
    
    .company__th,
    .employee__th,
    .payments__th,
    .earnings__th,
    .deduction__th,
    .total__th {
      background-color: #90aeff;
      color: #fff;
    }
  }
}
