@import './constants';

.slick-list {
	padding: 0 20% 0 0 !important;

	@include sm-up {
		margin-left: indent(-3) !important;
		padding: 0 !important;
	}
}

.slick-slide:last-child.slick-current {
	margin-left: -1.3%;
}

.slick-slide > div {
	@include sm-up {
		margin-left: indent(3);
	}
}


