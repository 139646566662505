@use 'sass:math';

@import '../constants.scss';

$container-full-width: 918;
$container-inner-width: 918 - 122;
$font-size-adjust: 1.125;

@function strip-units($number) {
	@return math.div($number, ($number * 0 + 1));
}

@function cqw-size($size, $container-size: $container-inner-width) {
	@return (math.div(strip-units($size), strip-units($container-size)) * 100) + unquote('cqw');
}

.pst1--new {
	width: 100%;
	position: relative;
	font-family: 'Arial';
	color: #000;
	padding: cqw-size(48px, $container-full-width) cqw-size(61px, $container-full-width);
	container-type: inline-size;

	&__main {
		display: flex;
	}

	.main-left {
		width: cqw-size(429px);
		margin-right: cqw-size(56px);

		&__spacing {
			margin-left: 10cqw;
		}

		&__payments {
			width: 100%;
		}

		&__deduction {
			margin-top: cqw-size(28px);
			width: 100%;
		}
	}

	.main-left-top {
		&__codes {
			display: inline-block;
			padding: 2px 3px 2px cqw-size(1px);
			background: rgba(196, 196, 196, 0.3);
		}

		&__company-about {
			margin-top: cqw-size(17px);
			margin-left: cqw-size(2px);
		}

		&__status {
			margin-top: cqw-size(24);
			font-size: cqw-size(10px);
			line-height: cqw-size(12px);
			margin-bottom: cqw-size(62px);

			th,
			td {
				padding-bottom: cqw-size(1px);
			}

			th {
				width: cqw-size(174px);
				padding-bottom: cqw-size(2px);
			}
		}
	}

	.codes {
		&__head {
			text-align: center;

			th {
				font-weight: normal;
				text-transform: uppercase;
				font-size: cqw-size(10px);
				line-height: 1.2;
				padding-right: cqw-size(8px);
				padding-top: cqw-size(3px);

				&:last-child {
					padding-right: 0;
				}
			}
		}

		&__body {
			text-align: center;

			td {
				padding-right: cqw-size(8px);
				font-size: cqw-size(10px);
				line-height: cqw-size(12px);
			}
		}
	}

	.payments {
		&__head {
			th {
				&:first-child {
					width: cqw-size(92px);
					font-size: cqw-size(14px);
				}
			}
		}

		&__body {
			td {
				&:nth-child(3) {
					padding-right: cqw-size(7px);
				}

				&:nth-child(4n) {
					padding-right: cqw-size(4px);
				}
			}
		}
	}

	.payments-thead {
		&__item {
			font-size: cqw-size(11px);
			line-height: cqw-size(16px);
			font-weight: 700;
			padding-bottom: cqw-size(4px);
			min-width: cqw-size(70px);
			border-bottom: 1px solid #000;
			text-align: right;
			padding-right: cqw-size(8px);

			&--empty {
				width: cqw-size(41px);
			}

			&--salary {
				min-width: cqw-size(140px);
			}
		}
	}

	.payments-tbody {
		&__item {
			padding-top: cqw-size(4px);
			font-size: cqw-size(13px);
			height: cqw-size(16px);
			text-align: right;

			&--accent {
				text-align: right;
				font-size: cqw-size(12px);
				line-height: cqw-size(16px);
				font-weight: 700;
				padding-top: cqw-size(4px);
				padding-bottom: cqw-size(5px);
				background-color: #e6e6e6;
				border-top: 1px solid #000;
				border-bottom: 1px solid #000;
			}
		}
	}

	.deduction {
		&__head {
			th {
				font-size: cqw-size(14px);
				line-height: cqw-size(16px);

				&:first-child {
					width: cqw-size(97px);
				}

				&:nth-child(2) {
					width: cqw-size(137px);
					padding-left: cqw-size(3px);
				}

				&:nth-child(3) {
					width: cqw-size(82px);
				}
			}
		}

		&__body {
			tr {
				&:nth-last-child(5) {
					td {
						&:nth-child(1),
						&:nth-child(2),
						&:nth-child(3) {
							// border-bottom: 1px solid #000;
							padding-bottom: cqw-size(4px);
						}
					}
				}

				&:nth-last-child(4) {
					td {
						padding-top: cqw-size(6px);
						padding-bottom: cqw-size(4px);
					}
				}
			}

			td {
				font-size: cqw-size(13px);
				line-height: cqw-size(16px);

				&:nth-child(2) {
					padding-left: cqw-size(3px);
				}

				&:nth-child(3) {
					padding-right: cqw-size(4px);
				}
			}
		}
	}

	.deduction-thead {
		&__item {
			padding-bottom: cqw-size(6px);
			border-bottom: 1px solid #000;

			&--empty {
				width: cqw-size(41px);
			}
		}
	}

	.deduction-tbody {
		&__item {
			padding-top: cqw-size(4px);
			padding-bottom: cqw-size(5px);

			&--secondary {
				padding-top: cqw-size(6px);
				padding-bottom: cqw-size(4px);
			}

			&--active {
				padding-top: cqw-size(5px);
				padding-bottom: cqw-size(5px);
				border-top: 1px solid #000;
				border-bottom: 1px solid #000;
				background-color: #e6e6e6;
				font-weight: 700;
			}

			&--other {
				font-weight: 700;
				text-decoration: underline;
			}
		}

		&__custom {
			margin-top: cqw-size(16px);
		}
	}

	.main-right {
		&__head {
			display: flex;
		}

		&__logo {
			margin-top: cqw-size(3px);
			margin-left: cqw-size(9px);

			img {
				width: cqw-size(85px);
				height: cqw-size(85px);
				object-fit: contain;
				object-position: top center;
			}
		}

		&__title {
			font-size: cqw-size(24px);
			line-height: cqw-size(28px);
			font-weight: 700;
			margin-bottom: cqw-size(14px);

			&--smaller {
				font-size: cqw-size(22px);
			}
		}

		&__payment-period {
			font-size: cqw-size(12px);
			line-height: cqw-size(12px);
			width: 100%;

			th,
			td {
				padding-bottom: cqw-size(7px);
			}

			th {
				width: cqw-size(137px);
				font-weight: 400;
			}
		}

		&__employee {
			margin-top: cqw-size(22px);
			margin-bottom: cqw-size(33px);
		}

		&__other-info {
			tr {
				vertical-align: bottom;
			}

			th {
				&:first-child {
					width: cqw-size(153px);
					padding-left: cqw-size(2px);
					padding-right: cqw-size(25px);
					font-size: cqw-size(14);
				}

				&:last-child {
					padding-right: cqw-size(6px);
				}
			}

			td {
				font-size: cqw-size(13px);
				line-height: cqw-size(16px);
				padding-top: cqw-size(6px);
				padding-bottom: cqw-size(6px);

				&:nth-child(3n) {
					padding-right: cqw-size(6px);
					text-align: right;
				}
			}
		}
	}

	.other-info {
		&__head-item {
			font-size: cqw-size(11px);
			line-height: cqw-size(16px);
			font-weight: 700;
			min-width: cqw-size(79px);
			border-bottom: 1px solid #000;
			padding-bottom: cqw-size(4px);
		}
	}

	&__footer {
		position: relative;
	}

	.footer {
		display: flex;
		margin-top: cqw-size(273px);
	}

	.footer-left {
		width: cqw-size(94px);

		&__logo {
			img {
				width: cqw-size(85px);
				height: cqw-size(85px);
				object-fit: contain;
				object-position: top center;
			}
		}
	}

	.footer-right {
		&__top {
			display: flex;
		}

		&__middle {
			margin-top: cqw-size(18px);
		}

		&__bottom {
			margin-top: cqw-size(53px);
			display: flex;
			justify-content: flex-end;
		}
	}

	.footer-right-top {
		&__left {
			width: cqw-size(390px);
		}

		&__about-company {
			margin-top: cqw-size(2px);
			margin-left: cqw-size(6px);
			font-weight: 400;
		}

		&__right {
			width: cqw-size(311px);
		}
	}

	.footer-right-middle {
		&__order-payment {
			width: 100%;

			th {
				padding-bottom: cqw-size(6px);
				border-bottom: 2px solid #000;
				font-size: cqw-size(14px);
				line-height: cqw-size(18px);
				text-transform: uppercase;

				&:first-child {
					text-transform: none;
					width: cqw-size(93px);
					padding-left: cqw-size(6px);
				}
			}

			td {
				font-size: cqw-size(14px);
				text-transform: uppercase;
				padding-top: cqw-size(2px);
				padding-bottom: cqw-size(1px);
				border-bottom: 2px solid #000;

				&:first-child {
					text-transform: none;
					width: cqw-size(93px);
					padding-left: cqw-size(6px);
					border-bottom: none;
				}

				&:nth-child(2) {
					width: cqw-size(486px);
					border-right: 2px solid #000;
					background-color: #e6e6e6;
				}
			}
		}

		&__account-payment {
			width: 100%;
			margin-top: cqw-size(32px);

			th {
				padding-bottom: cqw-size(4px);
				border-bottom: 2px solid #000;
				font-size: cqw-size(14px);
				line-height: cqw-size(18px);

				&:first-child {
					text-transform: none;
					width: 42%;
					padding-left: cqw-size(6px);
				}

				&:nth-child(2) {
					width: 25%;
				}
			}

			td {
				font-size: cqw-size(10px);
				text-transform: uppercase;
				padding-top: cqw-size(2px);
				padding-bottom: cqw-size(1px);

				&:first-child {
					font-weight: bold;
					text-transform: uppercase;
					width: cqw-size(93px);
					padding-left: cqw-size(6px);
					border-bottom: none;
				}
			}
		}
	}

	.footer-right-bottom {
		&__non-negotiable {
			margin-right: cqw-size(91px);
		}
	}

	.watermark-wrapper {
		.watermark-check {
			width: cqw-size(559px);
			margin-bottom: cqw-size(53px);
			bottom: 0;
		}

		.watermark-preview {
			width: cqw-size(504px);
			top: 0;
			margin-top: cqw-size(16px);
		}
	}

	.paystub-base-metrics__item {
		font-size: cqw-size(14px);
		line-height: cqw-size(12px);

		&:not(:last-child) {
			margin-bottom: cqw-size(6px);
		}
	}

	.pst-non-negotiable {
		font-size: cqw-size(24px);
		line-height: cqw-size(28px);
	}

	.advice-info {
		width: 100%;
		font-size: cqw-size(14px);

		th,
		td {
			padding-bottom: cqw-size(3px);
		}

		td {
			text-align: right;
		}
	}

	.form1099table-cell {
		font-size: cqw-size(12px);
		line-height: cqw-size(14px);
		display: flex;
		margin-bottom: cqw-size(6px);

		&__marker {
			margin-right: cqw-size(8px);
		}
	}
	.table-point {
		width: 100%;
		display: flex;

		&__marker {
			font-weight: 700;
			font-size: cqw-size(10px);
			line-height: 1.1;
			display: inline-block;
			margin-right: cqw-size(74px);
			flex: 0 0 auto;
		}

		&__content {
			width: 100%;
		}

		&__item {
			&:not(:last-child) {
				margin-bottom: cqw-size(6px);
			}

			&--center {
				text-align: center;
				margin-left: cqw-size(-10px);
			}
		}
	}
}
