@import 'src/styles/constants';

.item {
  position: relative;

  &__link {
    text-transform: uppercase;
    transition: $transition300ease;
    color: color(blue400);
  }

  &__link--active,
  &__link:hover {
    color: color(orange500);
  }
}

.dropdown {
  position: absolute;
  width: 163px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);

  &__inner {
    margin-top: indent(1);
    display: flex;
    background: color(white);
    box-shadow: 0px 6px 10px rgba(0, 17, 106, 0.1);
    border-radius: 8px;
    padding: indent(3) indent(2);
  }
}

.enter {
	.dropdown__inner {
		opacity: 0;
	}
}

.enter-active {
	.dropdown__inner {
		opacity: 1;
    transition: opacity 200ms;
	}
}

.exit {
	.dropdown__inner {
		opacity: 1;
	}
}

.exit-active {
	.dropdown__inner {
		opacity: 0;
    transition: opacity 200ms;
	}
}

