@import './custom-bootstrap';
@import './reset';
@import './constants.scss';
@import './slick.scss';
@import './typography.scss';

html,
body,
.layout-wrapper {
	padding: 0;
	margin: 0;
	font-family: $font-family;
	letter-spacing: 0.1px;
}

a {
	color: color(blue400);
	text-decoration: none;
	transition: $transition300ease;
}

.fs-tab {
	text-transform: uppercase;
	font-weight: 400;
}

.input-spaces {
	@include input-spaces;
}

.no-scroll {
	overflow: hidden;
}

#portals {
	z-index: 100;
	position: relative;
}

/* https://login.equalweb.com/custom-button.taf#navigation_Creating_your_own_custom_button */
#INDmenu-btn {
	display: none !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}
