@use 'sass:math';

@import '../constants.scss';

$container-full-width: 918;
$container-inner-width: 918 - 122;
$font-size-adjust: 1.125;

@function strip-units($number) {
	@return math.div($number, ($number * 0 + 1));
}

@function cqw-size($size, $container-size: $container-inner-width) {
	@return (math.div(strip-units($size), strip-units($container-size)) * 100) + unquote('cqw');
}

.pst6--new {
	$block-name: '.pst6';

	width: 100%;
	position: relative;
	font-family: 'Arial';
	color: #000;
	padding: cqw-size(48px, $container-full-width) cqw-size(61px, $container-full-width);

	.watermark-wrapper {
		.watermark-preview {
			width: cqw-size(505px);
			margin-top: cqw-size(72px);
			top: 0;
		}
	}

	.top {
		&__head {
			display: flex;
			justify-content: space-between;
		}

		&__body {
			padding: cqw-size(24px) cqw-size(35px) cqw-size(24px) cqw-size(35px);
			background: #f1f1f1;
		}
	}

	.top-head {
		&__left {
			font-size: cqw-size(14px);
			line-height: cqw-size(12px);
			text-transform: uppercase;
			margin: cqw-size(6px) 0 0 cqw-size(12px);
			font-style: italic;
		}

		&__company-name {
			margin-bottom: cqw-size(6px);
		}

		&--inline {
			display: block;
			margin-top: cqw-size(6px);
		}
	}

	.head-right {
		margin-right: cqw-size(62px);

		&__title {
			font-weight: 700;
			font-size: cqw-size(18px);
			line-height: cqw-size(24px);
			margin-bottom: cqw-size(6px);
		}

		&__dates {
			font-weight: 400;
			font-size: cqw-size(11px);
			line-height: cqw-size(11px);
		}

		&__item {
			margin-bottom: cqw-size(6px);
		}
	}

	.top-body {
		margin-top: cqw-size(11px);
		display: flex;
		justify-content: space-between;

		&__employee {
			width: cqw-size(385px);
			margin-left: auto;
			font-style: italic;
			font-weight: 700;
			font-size: cqw-size(13px);
			line-height: cqw-size(12px);
			text-transform: uppercase;
		}

		&__social {
			width: cqw-size(240px);
			font-weight: 700;
			font-size: cqw-size(13px);
			line-height: cqw-size(12px);
			text-transform: uppercase;

			span {
				font-weight: normal;
			}
		}
	}

	.body-employee {
		&__item {
			margin-bottom: cqw-size(6px);
		}
		&--inline {
			display: block;
			margin-top: cqw-size(6px);
		}
	}

	&__middle {
		margin-top: cqw-size(25px);
	}

	.middle {
		&__earnings {
			width: 100%;
		}
	}

	.earnings {
		width: 100%;
		margin-bottom: cqw-size(27px);

		&__th,
		&__td {
			font-size: cqw-size(11px);
			line-height: cqw-size(11px);
			padding-left: cqw-size(12px);
			padding-right: cqw-size(12px);

			&:nth-last-child(1),
			&:nth-last-child(2) {
				text-align: right;
			}
		}

		&__th {
			padding-top: cqw-size(11px);
			padding-bottom: cqw-size(11px);
			border-top: 1px solid #000;
			border-bottom: 1px solid #000;

			&:first-child {
				width: cqw-size(219px);
			}

			&:nth-child(2) {
				width: cqw-size(145px);
			}

			&:nth-child(3) {
				width: cqw-size(149px);
			}
		}

		&__tr {
			vertical-align: baseline;
			&:last-child {
				height: cqw-size(55px);
			}
		}

		&__td {
			padding-top: cqw-size(10px);
			padding-bottom: cqw-size(10px);
		}
	}

	.earnings-tfoot,
	.deduction-tfoot {
		&__td {
			font-size: cqw-size(11px);
			line-height: cqw-size(11px);
			padding-left: cqw-size(12px);
			padding-right: cqw-size(12px);
			background: #f1f1f1;
			padding-top: cqw-size(12px);
			padding-bottom: cqw-size(12px);
			font-weight: bold;

			&:nth-last-child(1),
			&:nth-last-child(2) {
				text-align: right;
			}
		}
	}

	.deduction {
		width: 100%;
		margin-bottom: cqw-size(25px);

		&__tbody {
			vertical-align: baseline;

			&::before {
				content: '';
				display: block;
				height: cqw-size(10px);
			}
		}

		&__th,
		&__td {
			font-size: cqw-size(11px);
			line-height: cqw-size(11px);
			padding-left: cqw-size(12px);
			padding-right: cqw-size(12px);

			&:nth-last-child(1),
			&:nth-last-child(2) {
				text-align: right;
			}
		}

		&__th {
			padding-top: cqw-size(11px);
			padding-bottom: cqw-size(11px);
			border-top: 1px solid #000;
			border-bottom: 1px solid #000;

			&:first-child {
				width: cqw-size(219px);
			}

			&:nth-child(2) {
				width: cqw-size(297px);
			}
		}

		&__tr {
			vertical-align: baseline;
			&:last-child {
				height: cqw-size(42px);
			}
		}

		&__td {
			padding-bottom: cqw-size(10px);
		}
	}

	.deduction-conclusion {
		font-weight: 700;
		font-size: cqw-size(14px);
		line-height: cqw-size(18px);
		margin-left: cqw-size(6px);
	}

	&__bottom {
		margin-top: cqw-size(25px);
	}

	.bottom {
		&__cut-line {
			width: 100%;
			height: cqw-size(19px);
			position: relative;
			margin-bottom: cqw-size(36px);

			&::before {
				position: absolute;
				right: 0;
				top: 50%;
				content: '';
				display: block;
				width: 99%;
				height: 1px;
				background-image: linear-gradient(to right, rgb(92, 92, 92) 33%, rgba(255, 255, 255, 0) 0%);
				background-position: bottom;
				background-size: cqw-size(12px) cqw-size(1px);
				background-repeat: repeat-x;
				transform: translateY(-50%);
			}
		}

		&__order {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: cqw-size(28px);
		}

		&__employee-name {
			font-weight: 700;
			font-size: cqw-size(14px);
			line-height: cqw-size(18px);
			text-transform: uppercase;
			margin-left: cqw-size(12px);
			margin-bottom: cqw-size(14px);
		}

		&__payment {
			width: 100%;
		}

		&__signature {
			width: cqw-size(312px);
			margin-left: auto;
			font-size: cqw-size(11px);
			line-height: cqw-size(11px);
			padding-top: cqw-size(15px);
			border-top: 1px solid #000;
		}
	}

	.bottom-order {
		&__company {
			font-size: cqw-size(14px);
			line-height: cqw-size(12px);
			text-transform: uppercase;
			margin-left: cqw-size(12px);
			font-style: italic;
		}

		&__company-name {
			margin-bottom: cqw-size(6px);
		}
	}

	.bottom-dates {
		font-weight: 400;
		font-size: cqw-size(11px);
		line-height: cqw-size(11px);
		margin-right: cqw-size(90px);

		&__item {
			margin-bottom: cqw-size(5px);
		}
	}

	.bottom-payment {
		margin-bottom: cqw-size(89px);

		&__th,
		&__td {
			font-size: cqw-size(11px);
			line-height: cqw-size(11px);
			font-weight: 700;
			padding: cqw-size(12px);
		}

		&__th {
			width: cqw-size(562px);
			background-color: #f1f1f1;
		}

		&__td {
			text-align: right;
			background-color: #dedede;
		}
	}
}

.pst6--new.pst6--gray {
	.top__body,
	.earnings-tfoot__td,
	.deduction-tfoot__td,
	.bottom-payment__th {
		border-top: 1px solid transparent;
		border-bottom: 1px solid transparent;
	}
}

.pst6--new.pst6--green {
	.top__body,
	.earnings__th,
	.earnings-tfoot__td,
	.deduction-tfoot__td,
	.bottom-payment__th {
		background-color: #70dd7f;
		border-top: 1px solid #70dd7f;
		border-bottom: 1px solid #70dd7f;
	}

	.deduction__th {
		border-top: 1px solid #70dd7f;
		border-bottom: 1px solid #70dd7f;
	}

	.bottom__signature {
		border-top: 1px solid #70dd7f;
	}

	.bottom-payment__td {
		background-color: #38b449;
		color: #fff;
	}
}

.pst6--new.pst6--blue {
	.top__body,
	.earnings__th,
	.earnings-tfoot__td,
	.deduction-tfoot__td,
	.bottom-payment__th {
		background-color: #90aeff;
		border-top: 1px solid #90aeff;
		border-bottom: 1px solid #90aeff;
	}

	.deduction__th {
		border-top: 1px solid #90aeff;
		border-bottom: 1px solid #90aeff;
	}

	.bottom__signature {
		border-top: 1px solid #90aeff;
	}

	.bottom-payment__td {
		background-color: #4673ec;
		color: #fff;
	}
}
