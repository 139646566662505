h1,
h2,
h3 {
	text-transform: uppercase;
	letter-spacing: 0.2px;
}

h1 {
	line-height: 1.25;
}

h2 {
	line-height: 1.33;
}

h3 {
	line-height: 1.5;
}

.h1 {
	@extend h1;
}

.h2 {
	@extend h2;
}

.h3 {
	@extend h3;
}

.h4 {
	@extend h4;
}

.h5 {
	@extend h5;
}

.h6 {
	@extend h6;
}
