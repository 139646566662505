.wrapper {
	min-height: 100vh;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.main {
	flex-grow: 1;
}

.footer {
	flex: 0 0 auto;
}
