@import 'src/styles/constants';

.footer {
	padding-top: indent(5);
	background: $gradient-header;

	@include md-up {
		padding-top: indent(4);
	}
}

.logo {
	margin-bottom: indent(3);
}

.nav-wrapper {
	display: flex;
}

.contact-wrapper {
	display: flex;
	flex-direction: column;
	margin-top: indent(5);

	@include md-up {
		flex-direction: row;
	}

	@include lg-up {
		flex-direction: column;
		margin-top: 0;
	}
}

.contact-left {
	margin-bottom: indent(4);

	@include md-up {
		min-width: 303px;
		margin-bottom: 0;
	}

	@include lg-up {
		min-width: auto;
	}
}

.contact-right {
	&__accessibe {
		@include md-up {
			margin-top: indent(4);
		}
	}
}

.phone {
	display: flex;
	align-items: center;
	margin-bottom: indent(4);

	@include md-up {
		margin-bottom: 0;
	}
}

.social-list {
	@include reset-list();
	display: flex;

	@include md-up {
		margin-top: 0;
	}
}

.social-list__item {
	transition: $transition300ease;

	&:hover {
		opacity: 0.7;
		transition: $transition300ease;
	}

	&:not(:last-child) {
		margin-right: indent(3);

		@include md-up {
			margin-right: 20px;
		}

		@include xl-up {
			margin-right: indent(3);
		}
	}
}

.social-list__item--icon {
	width: 40px;
	height: 40px;

	@include md-up {
		width: 34px;
		height: 34px;
	}

	@include xl-up {
		width: 40px;
		height: 40px;
	}
}

.phone__number {
	color: color(white);
	margin-left: indent(1);
}

.bottom-block {
	padding-top: indent(2);
	padding-bottom: indent(2);
	border-top: 1px solid rgba(234, 234, 255, 0.5);
	margin-top: indent(5);

	@include md-up {
		padding-top: 28px;
		padding-bottom: 28px;
		margin-top: 6px;
	}

	@include lg-up {
		padding-top: indent(4);
		padding-bottom: indent(4);
		margin-top: indent(3);
	}

	@include xl-up {
		margin-top: indent(3);
	}
}

.bottom {
	display: grid;
	grid-template-areas:
		'a'
		'c';
	column-gap: indent(2);
	text-align: center;

	&__menu {
		grid-area: m;
	}
	&__address {
		grid-area: a;
		color: color(white);
		opacity: 0.5;
	}
	&__copyright {
		grid-area: c;
		color: color(white);
		opacity: 0.5;
	}

	@include md-up {
		grid-template-areas:
			'a a a a a a a a a m m m '
			'c c c c c c c c c c c c';
		text-align: left;

		&__copyright {
			text-align: center;
		}
		&__address {
			font-size: 14px;
		}
	}

	@include lg-up {
		&__address {
			font-size: 16px;
		}
	}

	@include xl-up {
		grid-template-areas: unset;
		grid-template-columns: repeat(12, 1fr);

		&__copyright {
			grid-column: 1 / 5;
			text-align: left;
		}

		&__address {
			grid-column: 5 / 9;
			text-align: center;
		}
		&__menu {
			grid-column: 9 / 13;
		}
	}
}

.tablet__up_active {
	display: none;

	@include md-up {
		display: block;
	}
}

.nav__right {
	padding-left: indent(2);

	@include md-up {
		padding-left: indent(6);
	}

	@include lg-up {
		padding-left: 54px;
	}

	@include xl-up {
		padding-left: 63px;
	}
}

.nav__left {
	@include md-up {
		padding-left: 3px;
	}

	@include lg-up {
		padding-left: 0;
	}
}

.nav-contact {
	margin-top: indent(2);

	@include md-up {
		margin-top: 0;
		padding-left: 3px;
	}

	@include lg-up {
		padding-left: 0;
	}
}
