@import 'src/styles/constants';

.primary-list {
	@include reset-list();
	display: flex;
}

.primary-list__item {

	&:not(:first-child) {
		margin-left: indent(3);
	}

	&:last-child {
		margin-left: 41px;

		@include lg-up {
			margin-left: 93px;
		}

		@include xl-up {
			margin-left: indent(9);
		}
	}
}
