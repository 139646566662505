.typography-page {
	ol {
		padding-left:0;
		list-style-position:inside;

		li {
			margin-bottom: indent(3);

			&::marker {
				font-weight: 700;
			}
		}
	}

	ul {
		@include reset-list();
		margin-top: indent(2);
		margin-bottom: indent(2);

		li {
			position: relative;
			padding-left: 25px;
			margin-bottom: indent(1);

			&:before {
				content: '';
				position: absolute;
				top: 10px;
				left: 9px;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: color(blue800);
			}
		}
	}

	h3 {
		letter-spacing: 0;
		margin-bottom: indent(3) !important;

		@include md-up {
			margin-bottom: indent(2) !important;
		}
	}

	.fs-tab {
		line-height: 24px;
		letter-spacing: 0;
		@include md-up {
			margin-top: indent(2) !important;
			margin-bottom: indent(1);
		}
	}

	p {
		&:not(:last-child) {
			margin-bottom: indent(2);
		}
	}
}