@import 'src/styles/constants';

.nav {
	width: 50%;
}

.menu {
	@include reset-list();

	&__item {
		text-transform: uppercase;

		&:not(:last-child) {
			margin-bottom: indent(2);
		}
	}

	&__link {
		color: color(white);

		&:hover, &--active {
			color: color(orange500);
		}
	}
}
